import React, { useEffect, useRef, useState } from "react";
import "./SkuQualityCheck.scss";
import SkuQualityCheckCard from "../SkuQualityCheckCard/SkuQualityCheckCard";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

interface SkuQualityCheckSchema {
  id: number;
  key: string;
  unit: string | null;
  value: string | null;
  sku_internal_id: number;
}
interface SkuQualityCheckTestSchema {
  id: number;
  name: string;
  description: string;
  test_method: string | null;
}
interface SkuQualityCheckProps {
  addNewQuality: boolean;
  addNewQualityTest: boolean;
  skuId: number;
  setAddNewQuality: React.Dispatch<React.SetStateAction<boolean>>;
  setAddNewQualityTest: React.Dispatch<React.SetStateAction<boolean>>;
}
const SkuQualityCheck: React.FC<SkuQualityCheckProps> = ({
  addNewQuality,
  skuId,
  setAddNewQuality,
  addNewQualityTest,
  setAddNewQualityTest,
}) => {
  const [skuQualityChecks, setSkuQualityChecks] = useState<
    SkuQualityCheckSchema[]
  >([]);

  const [newPropertyKey, setNewPropertyKey] = useState("");

  useEffect(() => {
    getSkuQualityChecks();
  }, []);

  const getSkuQualityChecks = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.SKU.GET_SKU_QUALITY_CHECKS(skuId),
      );
      setSkuQualityChecks(data.data);
    } catch (error) {
      console.error("Error Fetching Sku Quality Checks", error);
    }
  };

  const handleDeleteQualityCheck = (qcId: number) => {
    const updatedSkuQualityChecks = skuQualityChecks.filter(
      (quality: SkuQualityCheckSchema, i) => quality.id !== qcId,
    );
    setSkuQualityChecks(updatedSkuQualityChecks);
  };

  const handleNewQualityAdd = async () => {
    try {
      if (newPropertyKey.length <= 0) {
        setAddNewQuality(false);
        return;
      }
      const { data } = await ApiService().client.post(
        URLS.SKU.CREATE_SKU_QUALITY_CHECK(skuId),
        {
          key: newPropertyKey,
        },
      );
      const newSkuQuality: SkuQualityCheckSchema = data.data;
      setSkuQualityChecks((prev) => [...prev, newSkuQuality]);
      setAddNewQuality(false);
      setNewPropertyKey("");
    } catch (error) {
      console.error("Error Creating Quality Check", error);
    }
  };

  return (
    <div className="sku-quality-check-main-container flex-column">
      <div className="quality-check-heading-container quality-check-grid xetgo-font-tag bold">
        <p className="empty-index p-12"></p>
        <div className="flex-row align-items-center">
          <div className="flex-row gap-8 align-items-center p-12 flex-1">
            <img
              height={12}
              width={12}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
              alt="list-icon"
            />
            <p className="">Parameters</p>
          </div>
          <div className="flex-row gap-8 align-items-center p-12 unit-container">
            <img
              height={12}
              width={12}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240731091657-book.svg"
              alt="unit-icon"
            />
            <p className="">Units</p>
          </div>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            height={12}
            width={12}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240731091736-book-open-check.svg"
            alt="unit-icon"
          />
          <p className="">Standard</p>
        </div>
      </div>

      <div className="flex-column gap-12">
        <div className="all-quality-check-container xetgo-font-tag ">
          {skuQualityChecks.map((quality: SkuQualityCheckSchema, index) => (
            <SkuQualityCheckCard
              key={quality.id}
              index={index}
              qualityCheck={quality}
              handleDeleteQualityCheck={handleDeleteQualityCheck}
            />
          ))}
          {skuQualityChecks.length == 0 && !addNewQuality && (
            <div className="empty-state-qc-card flex-column gap-12 align-items-center justify-content-center xetgo-font-tag bold">
              <img
                height={21}
                width={20}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808061403-Document+add.svg"
                alt="add-file-icon"
              />
              <p>Add Parameters to reflect here</p>
            </div>
          )}
          {addNewQuality && (
            <div className="quality-check-grid">
              <p className="index flex-row align-items-center p-12">
                {(skuQualityChecks.length.toString().length === 2
                  ? "0"
                  : skuQualityChecks.length.toString().length > 2
                  ? ""
                  : "00") +
                  (skuQualityChecks.length + 1)}
              </p>
              <div className="flex-row align-items-center">
                <div className="sku-quality-add-new-property-container flex-row gap-8 p-12 flex-1">
                  <input
                    className="flex-1 sku-qualityCheck-input px-12 py-8"
                    onChange={(e) => setNewPropertyKey(e.target.value)}
                    value={newPropertyKey}
                    placeholder="Enter the property ..."
                  />
                  <div className="flex-row gap-8">
                    <p
                      onClick={handleNewQualityAdd}
                      className="new-property-add-btn px-12 py-6 flex-row align-items-center justify-content-center cursor-pointer"
                    >
                      Save
                    </p>
                    <p
                      onClick={() => setAddNewQuality(false)}
                      className="new-property-cancel-btn  px-12 py-6 flex-row align-items-center justify-content-center cursor-pointer"
                    >
                      Cancel
                    </p>
                  </div>
                </div>
                <div className="p-12 right-border">
                  <p
                    className={`quality-check-input unit px-16 py-8 border-box `}
                  ></p>
                </div>
              </div>
              <div className="p-12 ">
                <p
                  className={`quality-check-input property-value px-16 py-8 border-box`}
                ></p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SkuQualityCheck;
