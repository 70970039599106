import { IOmsStatusTab } from '../../models/Oms';
import {
  DeliverySchedule,
  ProductVendorDetails,
  PurchaseOrderDetail,
  TaskCard,
  TaskCommentSchema,
  TaskDetail,
  TaskFileSchema,
} from '../../models/Task';
import {
  FetchFilterAction,
  FetchFilterFailureAction,
  FetchFilterSuccessAction,
  FilterPayload,
  OmsActions,
  FetchTasksAction,
  FetchTasksSuccessAction,
  FetchTasksFailureAction,
  SelectedFilterPayload,
  SetFilter,
  NewUserPayloadSchema,
  NewUserStartAction,
  FilterOptionSchema,
  NewUserSucccessAction,
  NewUserFailureAction,
  NewCompanyAddressPayloadSchema,
  NewCompanyAddressStartAction,
  NewCompanyAddressFailureAction,
  NewCompanyAddressSuccessAction,
  FetchCompanyAddressStartAction,
  FetchCompanyAddressSuccessAction,
  FetchCompanyAddressFailureAction,
  NewCompanyStartAction,
  NewCompanySuccessAction,
  NewCompanyFailureAction,
  RemoveCompanyAddress,
  FetchCompanyPocStartAction,
  FetchCompanyPocSuccessAction,
  FetchCompanyPocFailureAction,
  ClientPOC,
  FetchTaskDetailAction,
  FetchTaskDetailSuccessAction,
  FetchTaskDetailFailureAction,
  UpdateTaskDetailSchema,
  UpdateTaskDetailStartAction,
  UpdateTaskDetailSuccessAction,
  UpdateTaskDetailFailureAction,
  CreateTaskAction,
  CreateTaskFailureAction,
  CreateTaskSuccessAction,
  AddTaskProductStartAction,
  AddProductSchema,
  AddTaskProductSuccessAction,
  AddTaskProductFailureAction,
  POCPayload,
  POCSchema,
  UpdateTaskPOCAction,
  UpdateTaskPOCSuccessAction,
  UpdateTaskPOCFailureAction,
  UpdateTaskDetailResponseSchema,
  VENDOR_DETAIL_PAYLOAD,
  UpdateVendorDetailAction,
  UpdateVendorDetailSuccessAction,
  UpdateVendorDetailFailureAction,
  DeleteVendorDetailAction,
  DeleteVendorDetailSuccessAction,
  DeleteVendorDetailFailureAction,
  AddVendorDetailAction,
  AddVendorDetailSuccessAction,
  AddVendorDetailFailureAction,
  AddTaskCommentAction,
  AddTaskCommentSuccessAction,
  AddTaskCommentFailureAction,
  SampleAddressStartAction,
  SamplingPayload,
  SampleAddressSuccessAction,
  SampleAddressFailureAction,
  UpdateCoaAction,
  UpdateCoaSuccessAction,
  UpdateCoaPayloadSchema,
  UpdateCoaFailureAction,
  NewClientPayloadSchema,
  DeleteTaskProduct,
  DeleteTaskProductSuccessAction,
  DeleteTaskProductFailureAction,
  PurchaseOrderDetailFailureAction,
  PurchaseOrderDetailSuccessAction,
  PurchaseOrderDetailAction,
  FetchPurchaseRequestAction,
  FetchPurchaseRequestSuccessAction,
  FetchPurchaseRequestFailureAction,
  CreatePurchaseRequestAction,
  CreatePurchaseRequestSuccessAction,
  CreatePurchaseRequestFailureAction,
  SetSearch,
  AddPurchaseProductFailureAction,
  AddPurchaseProductSuccessAction,
  AddPurchaseProductStartAction,
  AddProductDeliveryScheduleStartAction,
  AddProductDeliveryScheduleSuccessAction,
  AddProductDeliveryScheduleFailureAction,
  DeleteTask,
  DeleteTaskSuccessAction,
  DeleteTaskFailureAction,
  AddRFQProduct,
  UpdatePurchaseOrderDetailSchema,
  UpdatePurchaseOrderDetailStartAction,
  UpdatePurchaseOrderDetailSuccessAction,
  UpdatePurchaseOrderDetailResponseSchema,
  AddPurchaseRequestCommentAction,
  AddPurchaseRequestCommentSuccessAction,
  AddPurchaseRequestCommentFailureAction,
  UpdatePurchaseOrderProductStartAction,
  UpdatePurchaseOrderProductSchema,
  UpdatePurchaseOrderProductSuccessAction,
  UpdatePurchaseOrderProductFailureAction,
  UpdateDeliveryDetailsStartAction,
  UpdateDeliveryDetailsSuccessAction,
  UpdateDeliveryDetailsFailureAction,
  UpdateDeliveryScheduleSchema,
  DeleteProductDeliveryScheduleStartAction,
  DeleteProductDeliveryScheduleSuccessAction,
  DeleteProductDeliveryScheduleFailureAction,
  FetchPurchaseRequestCommentAction,
  FetchPurchaseRequestCommentFailureAction,
  FetchPurchaseRequestCommentSuccessAction,
  UpdateTaskAttachmentsStartAction,
  UpdateTaskAttachmentsSuccessAction,
  UpdateTaskAttachmentsFailureAction,
  DeleteTaskAttachmentStartAction,
  DeleteTaskAttachmentSuccessAction,
  DeleteTaskAttachmentFailureAction,
  DeletePurchaseRequestVendorAction,
  DeletePurchaseRequestVendorSuccessAction,
  DeletePurchaseRequestVendorFailureAction,
} from './omsTypes';

// FILTERS

export const fetchFilter = (category: string): FetchFilterAction => ({
  type: OmsActions.FETCH_FILTER_LIST,
  payload: { category },
});

export const fetchFilterSuccess = (
  filters: FilterPayload
): FetchFilterSuccessAction => ({
  type: OmsActions.FETCH_FILTER_LIST_SUCCESS,
  payload: filters,
});

export const fetchFilterFailure = (
  error: string
): FetchFilterFailureAction => ({
  type: OmsActions.FETCH_FILTER_LIST_FAILURE,
  error,
});

export const setFilter = (filters: SelectedFilterPayload): SetFilter => ({
  type: OmsActions.SET_FILTER,
  payload: filters,
});

export const setSearch = (search: string): SetSearch => ({
  type: OmsActions.SET_SEARCH,
  payload: search,
});

// TASKS

export const fetchTasks = (
  category: string,
  status: IOmsStatusTab
): FetchTasksAction => ({
  type: OmsActions.FETCH_TASKS,
  payload: { category, status },
});

export const fetchTasksSuccess = (response: {
  tasks: TaskCard[];
  statuses: string[];
}): FetchTasksSuccessAction => ({
  type: OmsActions.FETCH_TASKS_SUCCESS,
  payload: response,
});

export const fetchTasksFailure = (error: string): FetchTasksFailureAction => ({
  type: OmsActions.FETCH_TASKS_FAILURE,
  error,
});

export const newUserStart = (
  payload: NewUserPayloadSchema
): NewUserStartAction => ({
  type: OmsActions.NEW_USER_START,
  payload,
});

export const newUserSuccess = (
  response: FilterOptionSchema
): NewUserSucccessAction => ({
  type: OmsActions.NEW_USER_SUCCESS,
  payload: response,
});

export const newUserFailure = (error: string): NewUserFailureAction => ({
  type: OmsActions.NEW_USER_FAILURE,
  error,
});

export const newCompanyStart = (
  payload: NewClientPayloadSchema
): NewCompanyStartAction => ({
  type: OmsActions.NEW_COMPANY_START,
  payload,
});

export const newCompanySuccess = (response: {
  company: FilterOptionSchema;
  company_pocs: ClientPOC[];
}): NewCompanySuccessAction => ({
  type: OmsActions.NEW_COMPANY_SUCCESS,
  payload: response,
});

export const newCompanyFailure = (error: string): NewCompanyFailureAction => ({
  type: OmsActions.NEW_COMPANY_FAILURE,
  error,
});

export const newCompanyAddressStart = (
  payload: NewCompanyAddressPayloadSchema
): NewCompanyAddressStartAction => ({
  type: OmsActions.NEW_COMPANY_ADDRESS_START,
  payload,
});

export const newCompanyAddressSuccess = (
  response: FilterOptionSchema
): NewCompanyAddressSuccessAction => ({
  type: OmsActions.NEW_COMPANY_ADDRESS_SUCCESS,
  payload: response,
});

export const newCompanyAddressFailure = (
  error: string
): NewCompanyAddressFailureAction => ({
  type: OmsActions.NEW_COMPANY_ADDRESS_FAILURE,
  error,
});

export const fetchCompanyAddressStart = (
  id: number
): FetchCompanyAddressStartAction => ({
  type: OmsActions.FETCH_COMPANY_ADDRESS_START,
  payload: id,
});

export const fetchCompanyAddressSuccess = (
  response: FilterOptionSchema[]
): FetchCompanyAddressSuccessAction => ({
  type: OmsActions.FETCH_COMPANY_ADDRESS_SUCCESS,
  payload: response,
});

export const fetchCompanyAddressFailure = (
  error: string
): FetchCompanyAddressFailureAction => ({
  type: OmsActions.FETCH_COMPANY_ADDRESS_FAILURE,
  error,
});

export const removeCompanyAddress = (): RemoveCompanyAddress => ({
  type: OmsActions.REMOVE_COMPANY_ADDRESS,
});

export const fetchCompanyPocStart = (
  id: number
): FetchCompanyPocStartAction => ({
  type: OmsActions.FETCH_COMPANY_POC_START,
  payload: { company_id: id },
});

export const fetchCompanyPocSuccess = (
  response: ClientPOC[]
): FetchCompanyPocSuccessAction => ({
  type: OmsActions.FETCH_COMPANY_POC_SUCCESS,
  payload: response,
});

export const fetchCompanyPocFailure = (
  error: string
): FetchCompanyPocFailureAction => ({
  type: OmsActions.FETCH_COMPANY_POC_FAILURE,
  error,
});

export const fetchTaskDetail = (response: {
  id: number;
  category: string;
  vendor_id?: string;
}): FetchTaskDetailAction => ({
  type: OmsActions.FETCH_TASK_DETAIL,
  payload: response,
});

export const fetchTaskDetailSuccess = (
  taskDetail: TaskDetail
): FetchTaskDetailSuccessAction => ({
  type: OmsActions.FETCH_TASK_DETAIL_SUCCESS,
  payload: taskDetail,
});

export const fetchTaskDetailFailure = (
  error: string
): FetchTaskDetailFailureAction => ({
  type: OmsActions.FETCH_TASK_DETAIL_FAILURE,
  error,
});

export const updateTaskDetailStart = (
  payload: UpdateTaskDetailSchema
): UpdateTaskDetailStartAction => ({
  type: OmsActions.UPDATE_TASK_DETAIL_START,
  payload,
});

export const updateTaskDetailSuccess = (
  response: UpdateTaskDetailResponseSchema
): UpdateTaskDetailSuccessAction => ({
  type: OmsActions.UPDATE_TASK_DETAIL_SUCCESS,
  payload: response,
});

export const updateTaskDetailFailure = (
  error: string
): UpdateTaskDetailFailureAction => ({
  type: OmsActions.UPDATE_TASK_DETAIL_FAILURE,
  error,
});

export const updateTaskAttachmentsStart = (payload: {
  taskId: number;
  attachments: FormData;
}): UpdateTaskAttachmentsStartAction => ({
  type: OmsActions.UPDATE_TASK_ATTACHMENTS_START,
  payload,
});

export const updateTaskAttachmentsSuccess = (
  response: TaskFileSchema[]
): UpdateTaskAttachmentsSuccessAction => ({
  type: OmsActions.UPDATE_TASK_ATTACHMENTS_SUCCESS,
  payload: response,
});

export const updateTaskAttachmentsFailure = (
  error: string
): UpdateTaskAttachmentsFailureAction => ({
  type: OmsActions.UPDATE_TASK_ATTACHMENTS_FAILURE,
  error,
});

export const deleteTaskAttachmentStart = (payload: {
  taskId: number;
  asset_record_id: number;
}): DeleteTaskAttachmentStartAction => ({
  type: OmsActions.DELETE_TASK_ATTACHMENT_START,
  payload,
});

export const deleteTaskAttachmentSuccess = (response: {
  asset_record_id: number;
}): DeleteTaskAttachmentSuccessAction => ({
  type: OmsActions.DELETE_TASK_ATTACHMENT_SUCCESS,
  payload: response,
});

export const deleteTaskAttachmentFailure = (
  error: string
): DeleteTaskAttachmentFailureAction => ({
  type: OmsActions.DELETE_TASK_ATTACHMENT_FAILURE,
  error,
});

export const createTask = (task: FormData): CreateTaskAction => ({
  type: OmsActions.CREATE_TASK,
  payload: task,
});

export const createTaskSuccess = (task: TaskCard): CreateTaskSuccessAction => ({
  type: OmsActions.CREATE_TASK_SUCCESS,
  payload: task,
});

export const createTaskFailure = (error: string): CreateTaskFailureAction => ({
  type: OmsActions.CREATE_TASK_FAILURE,
  error,
});

export const AddTaskProductStart = (
  payload: FormData
): AddTaskProductStartAction => ({
  type: OmsActions.ADD_TASK_PRODUCT_START,
  payload,
});

export const AddTaskProductSuccess = (
  response: AddRFQProduct
): AddTaskProductSuccessAction => ({
  type: OmsActions.ADD_TASK_PRODUCT_SUCCESS,
  payload: response,
});

export const AddTaskProductFailure = (
  error: string
): AddTaskProductFailureAction => ({
  type: OmsActions.ADD_TASK_PRODUCT_FAILURE,
  error,
});

export const updateTaskPOC = (pocs: POCPayload): UpdateTaskPOCAction => ({
  type: OmsActions.UPDATE_TASK_POC,
  payload: pocs,
});

export const updateTaskPOCSuccess = (
  pocs: POCSchema[]
): UpdateTaskPOCSuccessAction => ({
  type: OmsActions.UPDATE_TASK_POC_SUCCESS,
  payload: pocs,
});

export const updateTaskPOCFailure = (
  error: string
): UpdateTaskPOCFailureAction => ({
  type: OmsActions.UPDATE_TASK_POC_FAILURE,
  error,
});

export const addVendorDetail = (vendorDetail: {
  sourcingId: number;
  tpm_id: number;
  vendor_id: number;
}): AddVendorDetailAction => ({
  type: OmsActions.ADD_VENDOR_DETAIL,
  payload: vendorDetail,
});

export const addVendorDetailSuccess = (
  vendorDetailResponse: ProductVendorDetails
): AddVendorDetailSuccessAction => ({
  type: OmsActions.ADD_VENDOR_DETAIL_SUCCESS,
  payload: vendorDetailResponse,
});

export const addVendorDetailFailure = (
  error: string
): AddVendorDetailFailureAction => ({
  type: OmsActions.ADD_VENDOR_DETAIL_FAILURE,
  error,
});

export const updateVendorDetails = (
  vendorDetail: VENDOR_DETAIL_PAYLOAD
): UpdateVendorDetailAction => ({
  type: OmsActions.UPDATE_VENDOR_DETAIL,
  payload: vendorDetail,
});

export const updateVendorDetailsSuccess = (
  payload: VENDOR_DETAIL_PAYLOAD
): UpdateVendorDetailSuccessAction => ({
  type: OmsActions.UPDATE_VENDOR_DETAIL_SUCCESS,
  payload: payload,
});

export const updateVendorDetailsFailure = (
  error: string
): UpdateVendorDetailFailureAction => ({
  type: OmsActions.UPDATE_VENDOR_DETAIL_FAILURE,
  error,
});

export const deleteVendorDetails = (payload: {
  sourcingId: number;
  tpm_id: number;
  vendor_id: number;
}): DeleteVendorDetailAction => ({
  type: OmsActions.DELETE_VENDOR_DETAIL,
  payload: payload,
});

export const deleteVendorDetailsSuccess = (payload: {
  tpm_id: number;
  vendor_id: number;
}): DeleteVendorDetailSuccessAction => ({
  type: OmsActions.DELETE_VENDOR_DETAIL_SUCCESS,
  payload: payload,
});

export const deleteVendorDetailsFailure = (
  error: string
): DeleteVendorDetailFailureAction => ({
  type: OmsActions.DELETE_VENDOR_DETAIL_FAILURE,
  error,
});

export const addTaskComment = (payload: FormData): AddTaskCommentAction => ({
  type: OmsActions.ADD_TASK_COMMENT,
  payload: payload,
});

export const addTaskCommentSuccess = (
  payload: TaskCommentSchema
): AddTaskCommentSuccessAction => ({
  type: OmsActions.ADD_TASK_COMMENT_SUCCESS,
  payload: payload,
});

export const addTaskCommentFailure = (
  error: string
): AddTaskCommentFailureAction => ({
  type: OmsActions.ADD_TASK_COMMENT_FAILURE,
  error: error,
});

export const addPurchaseRequestComment = (
  payload: FormData
): AddPurchaseRequestCommentAction => ({
  type: OmsActions.ADD_PURCHASE_REQUEST_COMMENT,
  payload: payload,
});

export const addPurchaseRequestCommentSuccess = (
  payload: TaskCommentSchema
): AddPurchaseRequestCommentSuccessAction => ({
  type: OmsActions.ADD_PURCHASE_REQUEST_COMMENT_SUCCESS,
  payload: payload,
});

export const addPurchaseRequestCommentFailure = (
  error: string
): AddPurchaseRequestCommentFailureAction => ({
  type: OmsActions.ADD_PURCHASE_REQUEST_COMMENT_FAILURE,
  error: error,
});

export const sampleAddressStart = (
  payload: FormData
): SampleAddressStartAction => ({
  type: OmsActions.SAMPLE_ADDRESS_START,
  payload,
});

export const sampleAddressSuccess = (
  response: SamplingPayload
): SampleAddressSuccessAction => ({
  type: OmsActions.SAMPLE_ADDRESS_SUCCESS,
  payload: response,
});

export const sampleAddressFailure = (
  error: string
): SampleAddressFailureAction => ({
  type: OmsActions.SAMPLE_ADDRESS_FAILURE,
  error,
});

export const updateCoa = (coaPayload: FormData): UpdateCoaAction => ({
  type: OmsActions.UPDATE_COA,
  payload: coaPayload,
});

export const updateCoaSuccess = (
  payload: UpdateCoaPayloadSchema
): UpdateCoaSuccessAction => ({
  type: OmsActions.UPDATE_COA_SUCCESS,
  payload: payload,
});

export const updateCoaFailure = (error: string): UpdateCoaFailureAction => ({
  type: OmsActions.UPDATE_COA_FAILURE,
  error,
});

export const deleteTaskProduct = (payload: {
  tpm_id: number;
}): DeleteTaskProduct => ({
  type: OmsActions.DELETE_TASK_PRODUCT,
  payload: payload,
});

export const deleteTaskProductSuccess = (payload: {
  tpm_id: number;
}): DeleteTaskProductSuccessAction => ({
  type: OmsActions.DELETE_TASK_PRODUCT_SUCCESS,
  payload: payload,
});

export const deleteTaskProductFailure = (
  error: string
): DeleteTaskProductFailureAction => ({
  type: OmsActions.DELETE_TASK_PRODUCT_FAILURE,
  error,
});

// PURCHASE ORDER

export const purchaseOrderDetail = (response: {
  id: number;
  category: string;
  vendor_id?: string;
}): PurchaseOrderDetailAction => ({
  type: OmsActions.PURCHASE_ORDER_DETAIL,
  payload: response,
});

export const purchaseOrderDetailSuccess = (
  purchaseOrderDetails: PurchaseOrderDetail // interface of po detail from backend to be made
): PurchaseOrderDetailSuccessAction => ({
  type: OmsActions.PURCHASE_ORDER_DETAIL_SUCCESS,
  payload: purchaseOrderDetails,
});

export const purchaseOrderDetailFailure = (
  error: string
): PurchaseOrderDetailFailureAction => ({
  type: OmsActions.PURCHASE_ORDER_DETAIL_FAILURE,
  error,
});

export const deleteTask = (payload: { id: number }): DeleteTask => ({
  type: OmsActions.DELETE_TASK,
  payload: payload,
});

export const deleteTaskSuccess = (
  payload: string
): DeleteTaskSuccessAction => ({
  type: OmsActions.DELETE_TASK_SUCCESS,
  payload: payload,
});

export const deleteTaskFailure = (error: string): DeleteTaskFailureAction => ({
  type: OmsActions.DELETE_TASK_FAILURE,
  error,
});

// PURCHASE REQUEST //

export const fetchPurchaseRequests = (
  category: string,
  status: IOmsStatusTab
): FetchPurchaseRequestAction => ({
  type: OmsActions.FETCH_PURCHASE_REQUESTS,
  payload: { category, status },
});

export const fetchPurchaseRequestsSuccess = (response: {
  purchase_requests: TaskCard[];
  statuses: string[];
}): FetchPurchaseRequestSuccessAction => ({
  type: OmsActions.FETCH_PURCHASE_REQUESTS_SUCCESS,
  payload: response,
});

export const fetchPurchaseRequestsFailure = (
  error: string
): FetchPurchaseRequestFailureAction => ({
  type: OmsActions.FETCH_PURCHASE_REQUESTS_FAILURE,
  error,
});

export const createPurchaseRequest = (
  task: FormData
): CreatePurchaseRequestAction => ({
  type: OmsActions.CREATE_PURCHASE_REQUEST,
  payload: task,
});

export const createPurchaseRequestSuccess = (
  task: TaskCard
): CreatePurchaseRequestSuccessAction => ({
  type: OmsActions.CREATE_PURCHASE_REQUEST_SUCCESS,
  payload: task,
});

export const createPurchaseRequestFailure = (
  error: string
): CreatePurchaseRequestFailureAction => ({
  type: OmsActions.CREATE_PURCHASE_REQUEST_FAILURE,
  error,
});

export const addPurchaseProductStart = (
  payload: FormData
): AddPurchaseProductStartAction => ({
  type: OmsActions.ADD_PURCHASE_PRODUCT_START,
  payload,
});

export const addPurchaseProductSuccess = (
  response: AddProductSchema
): AddPurchaseProductSuccessAction => ({
  type: OmsActions.ADD_PURCHASE_PRODUCT_SUCCESS,
  payload: response,
});

export const addPurchaseProductFailure = (
  error: string
): AddPurchaseProductFailureAction => ({
  type: OmsActions.ADD_PURCHASE_PRODUCT_FAILURE,
  error,
});

export const addProductDeliveryScheduleStart = (
  payload: FormData
): AddProductDeliveryScheduleStartAction => ({
  type: OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_START,
  payload,
});

export const addProductDeliveryScheduleSuccess = (
  response: DeliverySchedule
): AddProductDeliveryScheduleSuccessAction => ({
  type: OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_SUCCESS,
  payload: response,
});

export const addProductDeliveryScheduleFailure = (
  error: string
): AddProductDeliveryScheduleFailureAction => ({
  type: OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_FAILURE,
  error,
});

export const updatePurchaseOrderDetailStart = (
  payload: UpdatePurchaseOrderDetailSchema
): UpdatePurchaseOrderDetailStartAction => ({
  type: OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_START,
  payload,
});

export const updatePurchaseOrderDetailSuccess = (
  response: UpdatePurchaseOrderDetailResponseSchema
): UpdatePurchaseOrderDetailSuccessAction => ({
  type: OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_SUCCESS,
  payload: response,
});

export const updatePurchaseOrderDetailFailure = (
  error: string
): UpdateTaskDetailFailureAction => ({
  type: OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_FAILURE,
  error,
});

export const updatePurchaseOrderProductStart = (
  payload: UpdatePurchaseOrderProductSchema
): UpdatePurchaseOrderProductStartAction => ({
  type: OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_START,
  payload,
});

export const updatePurchaseOrderProductSuccess = (
  response: UpdatePurchaseOrderProductSchema
): UpdatePurchaseOrderProductSuccessAction => ({
  type: OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_SUCCESS,
  payload: response,
});

export const updatePurchaseOrderProductFailure = (
  error: string
): UpdatePurchaseOrderProductFailureAction => ({
  type: OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_FAILURE,
  error,
});

export const updateDeliveryDetailsStart = (
  payload: UpdateDeliveryScheduleSchema
): UpdateDeliveryDetailsStartAction => ({
  type: OmsActions.UPDATE_DELIVERY_DETAILS_START,
  payload,
});

export const updateDeliveryDetailsSuccess = (
  response: UpdateDeliveryScheduleSchema
): UpdateDeliveryDetailsSuccessAction => ({
  type: OmsActions.UPDATE_DELIVERY_DETAILS_SUCCESS,
  payload: response,
});

export const updateDeliveryDetailsFailure = (
  error: string
): UpdateDeliveryDetailsFailureAction => ({
  type: OmsActions.UPDATE_DELIVERY_DETAILS_FAILURE,
  error,
});

export const deleteProductDeliveryScheduleStart = (
  payload: number
): DeleteProductDeliveryScheduleStartAction => ({
  type: OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_START,
  payload,
});

export const deleteProductDeliveryScheduleSuccess = (
  response: number
): DeleteProductDeliveryScheduleSuccessAction => ({
  type: OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_SUCCESS,
  payload: response,
});

export const deleteProductDeliveryScheduleFailure = (
  error: string
): DeleteProductDeliveryScheduleFailureAction => ({
  type: OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_FAILURE,
  error,
});

export const fetchPurchaseRequestComment = (payload: {
  po_id: number;
  vendor_id: number;
}): FetchPurchaseRequestCommentAction => ({
  type: OmsActions.FETCH_PURCHASE_REQUEST_COMMENT,
  payload,
});

export const fetchPurchaseRequestCommentSuccess = (
  response: TaskCommentSchema[]
): FetchPurchaseRequestCommentSuccessAction => ({
  type: OmsActions.FETCH_PURCHASE_REQUEST_COMMENT_SUCCESS,
  payload: response,
});

export const fetchPurchaseRequestCommentFailure = (
  error: string
): FetchPurchaseRequestCommentFailureAction => ({
  type: OmsActions.FETCH_PURCHASE_REQUEST_COMMENT_FAILURE,
  error,
});

export const deletePurchaseRequestVendor = (payload: {
  po_id: number;
  vendor_id: number;
}): DeletePurchaseRequestVendorAction => ({
  type: OmsActions.DELETE_PURCHASE_REQUEST_VENDOR,
  payload,
});

export const deletePurchaseRequestVendorSuccess = (
  response: number
): DeletePurchaseRequestVendorSuccessAction => ({
  type: OmsActions.DELETE_PURCHASE_REQUEST_VENDOR_SUCCESS,
  payload: response,
});

export const deletePurchaseRequestVendorFailure = (
  error: string
): DeletePurchaseRequestVendorFailureAction => ({
  type: OmsActions.DELETE_PURCHASE_REQUEST_VENDOR_FAILURE,
  error,
});
