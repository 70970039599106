import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { ProductDetailSchema } from '../../models/Task';
import { xetGoLogoBase64 } from '../../constants/xetgoLogoBase64Enc';
import { styles } from './QuotationReportStyles';
import { User } from '../../models/User';
import dayjs from 'dayjs';
import { commaFormat, FinalPrice } from '../../utils/Helper';
import { rupeeSymbolBase64 } from '../../constants/rupeeSymbolBase64Enc';

interface QuotationFormData {
  projectInfo: string;
  quotedDescription: string;
  summaryNotes: string;
  leadTimeFirstOrder: string;
  leadTimeSubsequentOrder: string;
  paymentTermsAdvance: string;
  paymentTermsRemaining: string;
  deliveryLocation: string;
  companyName: string;
  companyAddress: string;
  clientPocName: string;
  clientPocPhone: string;
  clientPocEmail: string;
  clientPocDesignation: string;
}

const QuotationReport: React.FC<{
  products: ProductDetailSchema[];
  productDetails: {
    [key: string]: {
      notes: string;
      qty: number;
      unit: string;
      gst: number;
      description: string;
    };
  };
  currentUser: User | undefined;
  formData: QuotationFormData;
  assigneeName: string;
}> = ({ products, productDetails, currentUser, formData, assigneeName }) => {
  const [quotationProducts, setQuotationProducts] =
    useState<ProductDetailSchema[]>(products);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [totalPriceWithGst, setTotalPriceWithGst] = useState(0);
  const [fileName, _setFileName] = useState(
    'Xetgo-' +
      formData.companyName.replaceAll(' ', '_') +
      '-' +
      formData.projectInfo.replaceAll(' ', '_') +
      '-' +
      dayjs().format('DD/MM/YY') +
      '.pdf'
  );

  useEffect(() => {
    if (products) {
      setQuotationProducts(
        products.map((prd) => {
          return { ...prd, ...productDetails[prd.tpm_id] };
        })
      );
      const totalPriceValue = products.reduce(
        (sum, product) => sum + calculatePrice(product),
        0
      );
      const totalGstValue = products.reduce(
        (sum, product) =>
          sum +
          calculatePrice(product) *
            ((productDetails[product.tpm_id || -1]?.gst ?? 18) / 100),
        0
      );
      const totalPriceWithGstValue = totalPriceValue + totalGstValue;
      console.log('TOTAL: ', totalPriceWithGstValue);
      setTotalPrice(totalPriceValue);
      setTotalGst(totalGstValue);
      setTotalPriceWithGst(totalPriceWithGstValue);
    }
  }, [products, productDetails]);

  const calculatePrice = (product: ProductDetailSchema) => {
    const price = FinalPrice(
      product.vendor_details && product.vendor_details.length > 0
        ? product.vendor_details[0].price
        : 0,
      product.margin || 0
    );
    const quantity = productDetails[product.tpm_id || -1]?.qty ?? 0;
    return price * quantity;
  };

  return (
    <Document title={fileName}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/* ------------- CLIENT DETAILS :: START ------------------ */}

          <View style={styles.header}>
            <View style={styles.imageContainer}>
              <Image src={xetGoLogoBase64} style={styles.image} />
            </View>
            <View style={styles.headerRightSection}>
              <View style={styles.headerRightUpperSection}>
                <View style={styles.titleSection}>
                  <Text style={styles.title}>Xetgo Private Limited</Text>
                </View>
                <View style={styles.addressSection}>
                  <Text style={styles.address}>
                    32 KH Layout Bengaluru, 560042
                  </Text>
                  <Text style={styles.cinNumber}>
                    CIN: U74999KA2020PTC135753
                  </Text>
                </View>
              </View>
              <View style={styles.headerRightLowerSection}>
                <Text style={styles.headerRightLowerSectionText}>
                  Thanks for your interest in Xetgo. Xetgo is a single strategic
                  source for world-class manufacturing of high-quality packaging
                  products and supply chain services. With practically unlimited
                  production capabilities and capacities from our suppliers
                  facilities in India and abroad.
                </Text>
              </View>
            </View>
          </View>

          {/* ------------- CLIENT DETAILS :: END ------------------ */}

          {/* ------------- CLIENT DETAILS :: START ------------------ */}

          <View style={styles.clientAndXetgoSection}>
            <View style={styles.clientSectionHeader}>
              <Text style={styles.clientInfoHeaderText}>
                Client Information
              </Text>
              <Text style={styles.xetgoInfoHeaderText}>Xetgo Information</Text>
            </View>

            <View style={styles.clientInfoContent}>
              <View style={styles.clientSection}>
                <Text>
                  {formData?.clientPocName} | {formData?.clientPocDesignation}{' '}
                </Text>
                <Text style={{ fontWeight: 'bold', fontSize: 9 }}>
                  {formData?.companyName}
                </Text>
                <Text>
                  Ph: {formData?.clientPocPhone} Email:{' '}
                  {formData?.clientPocEmail}
                </Text>
                <Text style={styles.clientAddress}>
                  {formData.companyAddress}
                </Text>
              </View>
              <View style={styles.xetgoSection}>
                <View style={styles.xetgoSectionRow}>
                  <Text style={styles.rowLabel}>
                    Quotation Reference Number
                  </Text>
                  <Text style={styles.rowValue}>
                    {'XG' +
                      dayjs().format('YYMMDD') +
                      formData.companyName.slice(0, 3).toUpperCase()}
                  </Text>
                </View>
                <View style={styles.xetgoSectionRow}>
                  <View style={styles.rowLabel}>
                    <Text> Quote Date </Text>
                  </View>
                  <View style={styles.rowValue}>
                    <Text>{dayjs().format('DD/MM/YY')} </Text>
                  </View>
                </View>
                <View style={styles.xetgoSectionRow}>
                  <View style={styles.rowLabel}>
                    <Text>Prepared By </Text>
                  </View>
                  <View style={styles.rowValue}>
                    <Text>{assigneeName}</Text>
                  </View>
                </View>
                <View style={styles.xetgoSectionRow}>
                  <View style={styles.rowLabel}>
                    <Text>Quote Validity </Text>
                  </View>
                  <View style={styles.rowValue}>
                    <Text>{dayjs().add(10, 'days').format('DD/MM/YY')} </Text>
                  </View>
                </View>
                <View style={styles.xetgoSectionRow}>
                  <View style={styles.rowLabel}>
                    <Text>Quoted Description </Text>
                  </View>
                  <View style={styles.rowValue}>
                    <Text>{formData.quotedDescription} </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.projectInfo}>
              <View style={styles.projectInfoHeader}>
                <Text style={styles.projectInfoHeaderText}>
                  Project Information
                </Text>
              </View>
              <View style={styles.projectInfoContent}>
                <View style={styles.projectInfoDescription}>
                  <View
                    style={{
                      height: '100%',
                      flex: 0.2,
                      padding: 3,
                      borderRight: '0.5px solid black',
                    }}
                  >
                    <Text style={{ fontSize: 9 }}> Project Info </Text>
                  </View>
                  <View style={{ height: '100%', flex: 0.8, padding: 3 }}>
                    <Text style={{ fontSize: 9 }}>{formData.projectInfo}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* ------------- CLIENT DETAILS :: END -------------------- */}

          {/* ------------- PRODUCT DETAILS :: START ----------------- */}

          <View style={styles.productDetails}>
            <View style={styles.productDetailHeader}>
              <View style={[styles.productDetailHeaderCell, { width: '20px' }]}>
                <Text>S No.</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { flex: 1 }]}>
                <Text>Description</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { width: '80px' }]}>
                <Text>Notes</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { width: '50px' }]}>
                <Text>Required Qty (Q)</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { width: '30px' }]}>
                <Text>Qty Type</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { width: '55px' }]}>
                <Text>Unit Price,</Text>
                <Text>Delivered (A)</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { width: '25px' }]}>
                <Text>GST</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { width: '74px' }]}>
                <Text>Total Price</Text>
                <Text>(A * Q)</Text>
              </View>
              <View style={[styles.productDetailHeaderCell, { width: '74px' }]}>
                <Text>Total GST (Total Price * GST)</Text>
              </View>
              <View
                style={[
                  styles.productDetailHeaderCell,
                  { width: '74px', border: 'none' },
                ]}
              >
                <Text> Total Price + GST</Text>
              </View>
            </View>

            <View style={styles.productDetailContent}>
              {quotationProducts.map((product, index) => (
                <View style={styles.productDetailRow} key={`item-row-${index}`}>
                  <View style={[styles.productDetailCell, { width: '20px' }]}>
                    <Text>{index + 1}</Text>
                  </View>
                  <View style={[styles.productDetailCell, { flex: 1 }]}>
                    <Text>{product.description}</Text>
                  </View>
                  <View
                    style={[
                      styles.productDetailCell,
                      { width: '80px', fontWeight: 'bold', overflow: 'hidden' },
                    ]}
                  >
                    <Text
                      style={{
                        maxWidth: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {productDetails[product?.tpm_id || -1]['notes'] || ''}
                    </Text>
                  </View>
                  <View style={[styles.productDetailCell, { width: '50px' }]}>
                    <Text>
                      {productDetails[product?.tpm_id || -1]['qty'] ||
                        product.quantity}{' '}
                    </Text>
                  </View>
                  <View style={[styles.productDetailCell, { width: '30px' }]}>
                    <Text>
                      {productDetails[product?.tpm_id || -1]['unit'] ||
                        product.unit}
                    </Text>
                  </View>
                  <View style={[styles.productDetailCell, { width: '55px' }]}>
                    <Image
                      src={rupeeSymbolBase64}
                      style={styles.rupeeSymbolImage}
                    />
                    <Text>
                      {FinalPrice(
                        product.vendor_details &&
                          product.vendor_details.length > 0
                          ? product.vendor_details[0].price
                          : 0,
                        product.margin || 0
                      ).toFixed(2)}
                    </Text>
                  </View>
                  <View style={[styles.productDetailCell, { width: '25px' }]}>
                    <Text>
                      {productDetails[product.tpm_id || -1]?.gst ?? 18}%
                    </Text>
                  </View>
                  <View style={[styles.productDetailCell, { width: '74px' }]}>
                    <Image
                      src={rupeeSymbolBase64}
                      style={styles.rupeeSymbolImage}
                    />
                    <Text>
                      {commaFormat(
                        (
                          FinalPrice(
                            product.vendor_details &&
                              product.vendor_details.length > 0
                              ? product.vendor_details[0].price
                              : 0,
                            product.margin || 0
                          ) * (productDetails[product.tpm_id || -1]?.qty ?? 0)
                        ).toFixed(2)
                      )}
                    </Text>
                  </View>
                  <View style={[styles.productDetailCell, { width: '74px' }]}>
                    <Image
                      src={rupeeSymbolBase64}
                      style={styles.rupeeSymbolImage}
                    />
                    <Text>
                      {commaFormat(
                        (
                          FinalPrice(
                            product.vendor_details &&
                              product.vendor_details.length > 0
                              ? product.vendor_details[0].price
                              : 0,
                            product.margin || 0
                          ) *
                          (productDetails[product.tpm_id || -1]?.qty ?? 0) *
                          ((productDetails[product.tpm_id || -1]?.gst ?? 18) /
                            100)
                        ).toFixed(2)
                      )}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.productDetailCell,
                      { width: '74px', border: 'none' },
                    ]}
                  >
                    <Image
                      src={rupeeSymbolBase64}
                      style={styles.rupeeSymbolImage}
                    />
                    <Text>
                      {commaFormat(
                        (
                          FinalPrice(
                            product.vendor_details &&
                              product.vendor_details.length > 0
                              ? product.vendor_details[0].price
                              : 0,
                            product.margin || 0
                          ) *
                            (productDetails[product.tpm_id || -1]?.qty ?? 0) +
                          FinalPrice(
                            product.vendor_details &&
                              product.vendor_details.length > 0
                              ? product.vendor_details[0].price
                              : 0,
                            product.margin || 0
                          ) *
                            (productDetails[product.tpm_id || -1]?.qty ?? 0) *
                            ((productDetails[product.tpm_id || -1]?.gst ?? 18) /
                              100)
                        ).toFixed(2)
                      )}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={styles.productDetailContentSummary}>
              <Text style={styles.summaryText}>Total</Text>
              <View
                style={[
                  styles.totalCell,
                  ...(totalPrice.toFixed(2).length >= 2 ||
                  totalGst.toFixed(2).length >= 2 ||
                  totalPriceWithGst.toFixed(2).length >= 2
                    ? [styles.amountIncreasedCell]
                    : []),
                  { width: '74px' },
                ]}
              >
                <Image
                  src={rupeeSymbolBase64}
                  style={[
                    styles.totalCell,
                    ...(totalPrice.toFixed(2).length >= 2 ||
                    totalGst.toFixed(2).length >= 2 ||
                    totalPriceWithGst.toFixed(2).length >= 2
                      ? [styles.totalRupeeSymbolImageSmall]
                      : [styles.totalRupeeSymbolImage]),
                  ]}
                />
                <Text>{commaFormat(totalPrice.toFixed(2))}</Text>
              </View>
              <View
                style={[
                  styles.totalCell,
                  ...(totalPrice.toFixed(2).length >= 2 ||
                  totalGst.toFixed(2).length >= 2 ||
                  totalPriceWithGst.toFixed(2).length >= 2
                    ? [styles.amountIncreasedCell]
                    : []),
                  { width: '74px' },
                ]}
              >
                <Image
                  src={rupeeSymbolBase64}
                  style={[
                    styles.totalCell,
                    ...(totalPrice.toFixed(2).length >= 2 ||
                    totalGst.toFixed(2).length >= 2 ||
                    totalPriceWithGst.toFixed(2).length >= 2
                      ? [styles.totalRupeeSymbolImageSmall]
                      : [styles.totalRupeeSymbolImage]),
                  ]}
                />
                <Text>{commaFormat(totalGst.toFixed(2))}</Text>
              </View>
              <View
                style={[
                  styles.totalCell,
                  ...(totalPrice.toFixed(2).length >= 2 ||
                  totalGst.toFixed(2).length >= 2 ||
                  totalPriceWithGst.toFixed(2).length >= 2
                    ? [styles.amountIncreasedCell]
                    : []),
                  { width: '74px', border: 'none' },
                ]}
              >
                <Image
                  src={rupeeSymbolBase64}
                  style={[
                    styles.totalCell,
                    ...(totalPrice.toFixed(2).length >= 2 ||
                    totalGst.toFixed(2).length >= 2 ||
                    totalPriceWithGst.toFixed(2).length >= 2
                      ? [styles.totalRupeeSymbolImageSmall]
                      : [styles.totalRupeeSymbolImage]),
                  ]}
                />
                <Text>
                  {commaFormat(totalPriceWithGst.toFixed(2).toString())}
                </Text>
              </View>
            </View>

            <View
              style={{
                padding: 4,
                borderBottom: '0.5px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: 4,
                fontSize: 8,
              }}
            >
              <Text>Notes : </Text>
              <Text> {formData.summaryNotes} </Text>
            </View>
          </View>

          {/* ------------- PRODUCT DETAILS :: END ------------------- */}

          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text
              style={{
                backgroundColor: '#1E1A55',
                padding: '4 8',
                color: 'white',
                fontSize: 9,
                fontWeight: 'bold',
              }}
            >
              Lead Time
            </Text>
            <View
              style={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: 4,
              }}
            >
              <Text style={styles.detailEndText}>
                First Order: {formData.leadTimeFirstOrder}
              </Text>
              <Text style={styles.detailEndText}>
                Subsequent Order: {formData.leadTimeSubsequentOrder}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text
              style={{
                backgroundColor: '#1E1A55',
                padding: '4 8',
                color: 'white',
                fontSize: 9,
                fontWeight: 'bold',
              }}
            >
              Payment Terms
            </Text>

            <Text
              style={[
                styles.detailEndText,
                {
                  padding: '8 4',
                  fontSize: 8,
                },
              ]}
            >
              Payment terms are {formData.paymentTermsAdvance}% advance and{' '}
              {formData.paymentTermsRemaining}% net 30 days from the date of the
              invoice issued by Xetgo. Any payments not made within 30 days of
              the date of the invoice shall be subject to a late payment charge
              of 24% per month (compounded) on the unpaid balance of any amount
              then passed due.
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '0.5px solid black',
            }}
          >
            <Text
              style={{
                backgroundColor: '#1E1A55',
                padding: '4 8',
                color: 'white',
                fontSize: 9,
                fontWeight: 'bold',
              }}
            >
              Delivery Location
            </Text>

            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text
                style={[
                  styles.detailEndText,
                  { padding: '4 8', borderBottom: '0.5px solid black' },
                ]}
              >
                {formData.deliveryLocation}
              </Text>
              <View
                style={{
                  padding: '8 4 4 8',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                <Text style={styles.detailEndText}>
                  We appreciate your interest in working with us. We hope the
                  above commercial offer is in line with your expectations. Give
                  us your feedback as well as any questions. Let us know what we
                  can do to earn your business and produce these components for
                  you.
                </Text>

                <Text style={styles.detailEndText}>Thank You,</Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <Text
                    style={[
                      styles.detailEndText,
                      { color: '#000059', fontWeight: 'bold' },
                    ]}
                  >
                    Xetgo Private Limited
                  </Text>
                  <Text
                    style={[
                      styles.detailEndText,
                      { color: '#000059', fontWeight: 'bold' },
                    ]}
                  >
                    {currentUser?.name}
                  </Text>

                  <Text style={[styles.detailEndText, { color: ' #0000ff' }]}>
                    {currentUser?.email}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.termsAndConditionSection}>
            <Text style={styles.termsAndConditionLabel}>
              Terms & Conditions:
            </Text>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>ORDER:</Text>
              <Text style={styles.termsAndConditionValue}>
                This quotation is subject to order by the customer within 20
                days. Additions or modifications to the provisions proposed by
                the customer will be deemed to be rejected by Xetgo, except to
                the extent agreed to by an authorized official of Xetgo in
                writing.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>PRICES:</Text>
              <Text style={styles.termsAndConditionValue}>
                Unless expressly stated otherwise in any quotation issued by
                Xetgo to the Client, all prices quoted are ex. Works and is
                expressed in Indian Rupees net and exclude the cost of packing
                and delivery. Any applicable Value Added Tax or any other sales
                tax or excise duties paid or payable by the sellers shall be
                added to the price and shall be payable by the Client. Price
                quotations are valid for a period of 20 days only from the date
                of quotation. Xetgo may, at their absolute discretion, accept or
                reject any order placed by the Buyer. Xetgo reserves the right
                to review the contract price(s) in the event of the Indian Rupee
                or fluctuation in the rates of raw materials.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>PAYMENT:</Text>
              <Text style={styles.termsAndConditionValue}>
                Payment terms are 0% advance and 100% net 30 days from the date
                of the invoice issued by Xetgo. Any payments not made within 30
                days of the date of the invoice shall be subject to a late
                payment charge of 24% per month (compounded) on the unpaid
                balance of any amount then passed due.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>
                DESIGN MODIFICATIONS:
              </Text>
              <Text style={styles.termsAndConditionValue}>NA</Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>
                APPROVAL OF PRODUCTION SAMPLES:
              </Text>
              <Text style={styles.termsAndConditionValue}>
                Xetgo will submit FAIR samples for customer to review and
                approve. Production batch will be initiated only after the
                approval of FAIR samples by customer.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>
                SURFACE QUALITY:
              </Text>
              <Text style={styles.termsAndConditionValue}>NA</Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>DELIVERY:</Text>
              <Text style={styles.termsAndConditionValue}>
                Delivery and price of orders for pilot runs or smaller
                production quantities is to be negotiated at time of placement
                of such orders and is to be subject to availability of
                Xetgo&apos;s machine capacity.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>
                PRODUCTION AND SHIPMENTS:
              </Text>
              <Text style={styles.termsAndConditionValue}>
                Upon acceptance of the quotation and raising an invoice Xetgo
                will deliver the states SKUs in 15 working days from the time PO
                has been raised
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>WARRANTY:</Text>
              <Text style={styles.termsAndConditionValue}>
                Defective products, including products that do not meet mutually
                agreed upon specifications, will be replaced, corrected, or
                credit allowed. No allowances will be made for any damages or
                expenses incurred beyond the actual invoice value of goods
                shipped and billed by Xetgo.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>TAXES:</Text>
              <Text style={styles.termsAndConditionValue}>
                Customer agrees to pay or reimburse Xetgo for any sales, excise
                or other tax levied by any government agency, federal, state, or
                local, which Xetgo may be required to collect or pay upon the
                sale of the items quoted and any tax upon Xetgo measured in
                whole or in part by the amount of such sale.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>
                LIMITATION OF LIABILITY:
              </Text>
              <Text style={styles.termsAndConditionValue}>
                In no event shall xetgo&apos;s liability for any product exceed
                the amounts paid to xetgo for such product hereunder. in no
                event will xetgo be liable for costs of procurement of
                substitute goods by customer; loss of use, data, profits,
                consequential losses, indirect losses or special damages,
                product recall costs, or patent claims. these limitations shall
                apply notwithstanding any failure of essential purpose of any
                limited remedy.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>
                CLAIMS AND RETURNS:
              </Text>
              <Text style={styles.termsAndConditionValue}>
                Claims of any nature must be made within 15 days of receipt.
                Returns will not be accepted for any reason without Xetgo&apos;s
                prior written authorization.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>
                CHANGES AND CANCELLATIONS:
              </Text>
              <Text style={styles.termsAndConditionValue}>
                Orders are not subject to changes except on such terms as to
                protect Xetgo against loss and expressly agreed to by Xetgo in
                writing.
              </Text>
            </View>
            <View style={styles.termsAndConditionPoint}>
              <Text style={styles.termsAndConditionLabel}>DELAYS:</Text>
              <Text style={styles.termsAndConditionValue}>
                XETGO is not responsible for delays or failures in making
                delivery per agreed terms due to causes beyond its control
                including, but not limited to, acts or regulations of any
                governmental authority, fire, work stoppages or slowdowns,
                accident, flood, war, national emergency, acts of God, public
                disorders, transportation shortages and delays and shortages of
                fuel or materials.
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default QuotationReport;
