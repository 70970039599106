import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  IDeliveryQualityCheckDetail,
  ISkuQualityCheck,
} from "../../models/DeliveryDetail";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import XetToggle from "../shared/xet-toggle/XetToggle";
import "./DeliveryDetailQualityCheckContent.scss";

interface IDeliveryDetailQualityCheckContent {
  purchased_product_delivery_schedule_id: number;
}

const DeliveryDetailQualityCheckContent: React.FC<
  IDeliveryDetailQualityCheckContent
> = ({ purchased_product_delivery_schedule_id }) => {
  const [qualityCheckDetails, setQualityCheckDetails] =
    useState<IDeliveryQualityCheckDetail>();
  const [editableField, setEditableField] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isEditingDeliveredQuantity, setIsEditingDeliveredQuantity] =
    useState(false);
  const [deliveredQuantityValue, setDeliveredQuantityValue] =
    useState<number>(0);
  const [toggleState, setToggleState] = useState<boolean[]>(
    () =>
      qualityCheckDetails?.sku_quality_checks.map((qc) => qc.approved) || [],
  );

  useEffect(() => {
    getDeliveryQualityCheckDetails(purchased_product_delivery_schedule_id).then(
      (r) => r,
    );
  }, [purchased_product_delivery_schedule_id]);

  const getDeliveryQualityCheckDetails = async (id: number) => {
    try {
      const { data } = await ApiService().client.get(
        URL.DELIVERY_DETAILS.GET_DELIVERY_QUALITY_CHECK_DETAILS(id),
      );
      setQualityCheckDetails(data);
      setToggleState(
        data.sku_quality_checks.map((qc: ISkuQualityCheck) => qc.approved),
      );
    } catch (error) {
      console.error("Error fetching quality check details", error);
    }
  };

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    category: string,
  ) => {
    try {
      const files = e.target.files;
      if (files && files.length > 0) {
        const formData = new FormData();
        formData.append("category", category);

        Array.from(files).forEach((file) => {
          formData.append("files[]", file);
          formData.append("file_names[]", file.name);
        });

        const { data } = await ApiService().client.post(
          URL.DELIVERY_DETAILS.ADD_ATTACHMENTS(
            Number(purchased_product_delivery_schedule_id),
          ),
          formData,
        );
        setQualityCheckDetails((prev) =>
          prev
            ? {
                ...prev,
                delivery_attachments: [
                  ...prev.delivery_attachments,
                  ...data.data,
                ],
              }
            : prev,
        );
      }
    } catch (error) {
      console.error("error uploading files", error);
    }
  };

  const handleEditClick = (qcIndex: number) => {
    if (qualityCheckDetails && qualityCheckDetails.sku_quality_checks) {
      setEditableField(qcIndex);
      setInputValue(
        qualityCheckDetails.sku_quality_checks[qcIndex].observed_val || "",
      );
    }
  };

  const handleDeliveredQuantityChange = (value: number) => {
    setDeliveredQuantityValue(value);
  };

  const handleDeliveredQuantitySave = async () => {
    if (qualityCheckDetails) {
      qualityCheckDetails.delivered_quantity = deliveredQuantityValue;
      await ApiService().client.put(
        URL.DELIVERY_DETAILS.UPDATE_DELIVERY_QUALITY_CHECK_DETAILS(
          Number(purchased_product_delivery_schedule_id),
        ),
        {
          delivered_quantity: deliveredQuantityValue,
        },
      );
    }
    setIsEditingDeliveredQuantity(false); // Revert to non-editable
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const handleObservedValueSave = () => {
    if (editableField !== null && qualityCheckDetails) {
      const qc = qualityCheckDetails.sku_quality_checks[editableField];
      qc.observed_val = inputValue;
      saveDetails(qc.delivered_sku_internal_quality_check_id, inputValue).then(
        (r) => r,
      );
    }
    setEditableField(null); // Revert to non-editable
  };

  const saveDetails = async (
    delivered_sku_internal_quality_check_id: number,
    observed_val: string,
  ) => {
    await ApiService().client.put(
      URL.DELIVERY_DETAILS.UPDATE_DELIVERY_QUALITY_CHECK_DETAILS(
        Number(purchased_product_delivery_schedule_id),
      ),
      {
        delivered_sku_internal_quality_check_id,
        observed_val,
      },
    );
  };

  const handleToggleChange = async (
    qcIndex: number,
    delivered_sku_internal_quality_check_id: number,
    value: boolean,
  ) => {
    setToggleState((prevState) => {
      const updatedState = [...prevState];
      updatedState[qcIndex] = value;
      return updatedState;
    });

    try {
      await ApiService().client.put(
        URL.DELIVERY_DETAILS.UPDATE_DELIVERY_QUALITY_CHECK_DETAILS(
          Number(purchased_product_delivery_schedule_id),
        ),
        {
          delivered_sku_internal_quality_check_id,
          approved: value,
        },
      );
    } catch (error) {
      console.error("Failed to save toggle value:", error);
    }
  };

  return (
    <div className="quality-check-details-container flex-column gap-12">
      <div className="client-details-section flex-row align-items-center justify-content-space-between px-12 py-6">
        <div className="client-details-left-section p-12 flex-column gap-4">
          <span className="xetgo-font-tag-bold" style={{ color: "#111111" }}>
            {qualityCheckDetails?.client_details.client.name}
          </span>
          <span className="xetgo-font-tag" style={{ color: "#919191" }}>
            {qualityCheckDetails?.client_details.client_poc.name}
          </span>
          <span className="xetgo-font-tag" style={{ color: "#111111" }}>
            {qualityCheckDetails?.approved_quantity}
          </span>
          <span className="xetgo-font-tag" style={{ color: "#111111" }}>
            {dayjs(qualityCheckDetails?.actual_delivery_date).format(
              "DD-MM-YY",
            )}
          </span>
        </div>

        <div className="client-details-right-section p-12 flex-column gap-4">
          <span className="xetgo-font-tag-bold" style={{ color: "#111111" }}>
            {qualityCheckDetails?.address.city}
          </span>
          <span className="xetgo-font-tag" style={{ color: "#919191" }}>
            {qualityCheckDetails?.address.address_line}
          </span>
          <span className="xetgo-font-tag" style={{ color: "#111111" }}>
            {qualityCheckDetails?.address.pincode}
          </span>
        </div>
      </div>

      <div className="quality-check-content flex-column gap-16 p-12">
        <div className="sku-details-header flex-row align-items-center justify-content-space-between">
          <div className="sku-name xetgo-font-tag-bold">
            {qualityCheckDetails?.sku_name}
            <span style={{ color: "#AA5200" }}>
              {" "}
              {qualityCheckDetails?.sku_uuid}{" "}
            </span>
          </div>

          <div className="sku-quantity-and-status flex-row align-items-center gap-12">
            <div className="sku-delivered-quantity xetgo-font-tag-bold px-12 py-6">
              {isEditingDeliveredQuantity ? (
                <input
                  type="text"
                  value={deliveredQuantityValue}
                  onChange={(e) =>
                    handleDeliveredQuantityChange(Number(e.target.value))
                  }
                  onBlur={handleDeliveredQuantitySave} // Save on blur
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleDeliveredQuantitySave();
                    }
                  }}
                  autoFocus
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <div
                  onClick={() => {
                    setIsEditingDeliveredQuantity(true);
                    setDeliveredQuantityValue(
                      qualityCheckDetails?.delivered_quantity || 0,
                    );
                  }}
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    boxSizing: "border-box",
                  }}
                  className="flex-row align-items-center justify-content-space-between"
                >
                  {qualityCheckDetails?.delivered_quantity || 0}
                  <span style={{ color: "#878787" }}>
                    {qualityCheckDetails?.sku_unit}
                  </span>
                </div>
              )}
            </div>

            <div className="sku-uuid px-8 py-6 xetgo-font-tag">
              {qualityCheckDetails?.po_number}
            </div>

            <div
              className="delivery-pod cursor-pointer px-12 py-6 flex-row align-items-center gap-24 xetgo-font-tag"
              onClick={() =>
                window.open(qualityCheckDetails?.pod.url, "_blank")
              }
            >
              <span style={{ color: "#5151EC" }}> POD </span>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                alt="upload"
              />
            </div>

            <div
              className="delivery-status cursor-pointer px-16 py-6 flex-row align-items-center gap-24 xetgo-font-tag"
              style={{ background: "#880000" }}
            >
              <span style={{ color: "#FFFFFF" }}>
                {" "}
                {
                  qualityCheckDetails?.purchase_request_product_delivery_status
                }{" "}
              </span>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241005114418-chevron-down-white.svg"
                alt="upload"
              />
            </div>
          </div>
        </div>

        <div className="quality-check-table-wrapper mt-16">
          <table className="quality-check-table">
            <thead className="table-head">
              <tr>
                <th className="table-header xetgo-font-tag-bold">Parameters</th>
                <th className="table-header xetgo-font-tag-bold">Standard</th>
                <th className="table-header xetgo-font-tag-bold">Vendor</th>
                <th className="table-header xetgo-font-tag-bold">Observed</th>
                <th className="table-header xetgo-font-tag-bold">Approved</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {qualityCheckDetails &&
                qualityCheckDetails.sku_quality_checks.map(
                  (qualityCheck, qcIndex) => {
                    return (
                      <tr key={qcIndex} className="table-row">
                        <td className="table-cell xetgo-font-tag p-12">
                          {qualityCheck.key}
                        </td>
                        <td className="table-cell xetgo-font-tag p-12">
                          {qualityCheck.standard_value}
                        </td>
                        <td className="table-cell xetgo-font-tag p-12">
                          {qualityCheck.vendor_value}
                        </td>
                        <td
                          className="table-cell xetgo-font-tag p-12"
                          style={{ backgroundColor: "#DFE6D2" }}
                        >
                          {editableField === qcIndex ? (
                            <input
                              type="text"
                              value={inputValue}
                              onChange={(e) =>
                                handleInputChange(e.target.value)
                              }
                              onBlur={handleObservedValueSave} // Save when the input loses focus
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleObservedValueSave();
                                }
                              }}
                              className="xetgo-font-tag"
                              autoFocus
                              style={{
                                width: "100%",
                                height: "100%",
                                boxSizing: "border-box",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                border: "4px solid #DFE6D2",
                                textAlign: "center",
                              }}
                            />
                          ) : (
                            <div
                              onClick={() => handleEditClick(qcIndex)}
                              style={{
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                boxSizing: "border-box",
                              }}
                            >
                              {qualityCheck.observed_val ||
                                "Click to edit observed value"}
                            </div>
                          )}
                        </td>
                        <td
                          className="table-cell xetgo-font-tag p-12"
                          style={{ width: "60px" }}
                        >
                          <XetToggle
                            toggleValue={toggleState[qcIndex]}
                            onToggle={(val: boolean) =>
                              handleToggleChange(
                                qcIndex,
                                qualityCheck.delivered_sku_internal_quality_check_id,
                                val,
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  },
                )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="attachments-section p-12">
        <div className="image-section">
          <div
            className="xetgo-font-tag-bold py-12"
            style={{
              color: "#9D9D9D",
              borderBottom: "1px solid #A7A7A7",
              boxSizing: "border-box",
            }}
          >
            Delivered Batch Images
          </div>

          <div className="batch-all-image-main-container flex-row gap-8">
            {qualityCheckDetails &&
              qualityCheckDetails.delivery_attachments
                .filter((file) => file.attachment_type === "BATCH_IMAGE")
                .map((file, index) => (
                  <div
                    key={index}
                    className="sku-image-container position-relative"
                  >
                    <a href={file.url} target="_blank" rel="noreferrer">
                      <img
                        className="sku-detail-image"
                        height={61}
                        width={61}
                        src={file.url}
                        alt="images"
                      />
                    </a>
                  </div>
                ))}
            <div className="add-batch-image-main-container">
              <label
                htmlFor="accepted-file-upload"
                className="add-batch-image-label flex-column gap-10 align-items-center justify-content-center px-16 py-12 cursor-pointer"
              >
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808140932-plus-circle.svg"
                  alt="add-icon"
                />
                <p>Add Images</p>
              </label>
              <input
                id="accepted-file-upload"
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, "BATCH_IMAGE")}
              />
            </div>
          </div>
        </div>

        <div className="image-section">
          <div
            className="xetgo-font-tag-bold py-12"
            style={{
              color: "#9D9D9D",
              borderBottom: "1px solid #A7A7A7",
              boxSizing: "border-box",
            }}
          >
            Damaged Images
          </div>

          <div className="batch-all-image-main-container flex-row gap-8">
            {qualityCheckDetails &&
              qualityCheckDetails.delivery_attachments
                .filter((file) => file.attachment_type === "DAMAGED_IMAGE")
                .map((file, index) => (
                  <div
                    key={index}
                    className="sku-image-container position-relative"
                  >
                    <a href={file.url} target="_blank" rel="noreferrer">
                      <img
                        className="sku-detail-image"
                        height={61}
                        width={61}
                        src={file.url}
                        alt="images"
                      />
                    </a>
                  </div>
                ))}
            <div className="add-batch-image-main-container">
              <label
                htmlFor="rejected-file-upload"
                className="add-batch-image-label flex-column gap-10 align-items-center justify-content-center px-16 py-12 cursor-pointer"
              >
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808140932-plus-circle.svg"
                  alt="add-icon"
                />
                <p>Add Images</p>
              </label>
              <input
                id="rejected-file-upload"
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, "DAMAGED_IMAGE")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetailQualityCheckContent;
