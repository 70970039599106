import React, { useEffect, useState } from "react";
import "./SkuPricingTabCard.scss";
import { SkuVendorPriceHistory } from "../../models/SkuListSchema";
import URL from "../../urls/Urls";
import ApiService from "../../services/apiService";

interface SkuPricingProps {
  priceEntry: SkuVendorPriceHistory;
  handleDeletion: (id: number) => void;
  handlePricingListUpdate: (id: number, data: any) => void;
}
interface PricingHistory {
  moq: number;
  price: number;
  lead_time: number;
  created_at: string;
}

const SkuPricingTabCard: React.FC<SkuPricingProps> = ({
  priceEntry,
  handleDeletion,
  handlePricingListUpdate,
}) => {
  const [priceDetail, setPriceDetail] = useState(priceEntry);
  const [priceDetailHistory, setPridceDetailHistory] = useState<
    PricingHistory[]
  >([]);
  const [moq, setMoq] = useState(priceEntry.moq);
  const [price, setPrice] = useState(priceEntry.price);
  const [lead_time, setLeadTime] = useState(priceEntry.lead_time);
  const [isEditing, setIsEditing] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const getHistorySkuPricing = async () => {
    const { data } = await ApiService().client.get(
      URL.SKU.GET_HISTORY_SKU_INTERNAL_PRICING(priceDetail.id),
    );
    setPridceDetailHistory(data.data);
  };

  const formateDate = (data: string) => {
    const date = new Date(data);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  const handleCancle = () => {
    setPrice(priceEntry.price);
    setMoq(priceEntry.moq);
    setLeadTime(priceEntry.lead_time);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();

      formData.append("moq", moq.toString());
      formData.append("lead_time", lead_time.toString());
      formData.append("price", price.toString());
      if (
        priceEntry.price === price &&
        priceEntry.lead_time === lead_time &&
        priceEntry.moq === moq
      ) {
        handleCancle();
        return;
      }
      const { data } = await ApiService().client.put(
        URL.SKU.UPDATE_SKU_INTERNAL_PRICING(priceEntry.id),
        {
          moq: moq,
          time: lead_time,
          price: price,
        },
      );
      handlePricingListUpdate(priceEntry.id, data.price);
    } catch (error) {
      console.error("Error Updating ", error);
    }
    setIsEditing(false);
  };

  const handleDelete = async () => {
    try {
      const { data } = await ApiService().client.delete(
        URL.SKU.DELETE_SKU_INTERNAL_PRICING(priceEntry.id),
      );
      handleDeletion(priceEntry.id);
    } catch (error) {
      console.error("Error deleting", error);
    }
  };

  return (
    <div className="sku-pricing-card-main-container sku-pricing-main-grid align-items-center">
      <div className="sku-left-grid">
        <div className="address-detail-main-container flex-column gap-4 p-12 xetgo-font-tag">
          <p className="state bold">{priceDetail.state}</p>
          <p className="address-detail sub-title">
            {priceDetail.address_line},{priceDetail.city}, {priceDetail.country}
            , {priceDetail.pincode} <span></span>
          </p>
        </div>

        <p className="p-12 vendor-value  sub-value cell xetgo-font-tag">
          {priceDetail.vendor_name}
        </p>
      </div>

      <div className="all-history-main-container">
        <div className="sku-right-grid">
          {!isEditing ? (
            <p className="p-12 moq cell xetgo-font-tag">
              {priceDetail.moq} {priceDetail.unit}
            </p>
          ) : (
            <div
              className={`input-main-container p-12 moq cell xetgo-font-tag flex-row ${
                isEditing && "py-17  "
              }`}
            >
              <input
                type="number"
                className={`input-box xetgo-font-tag full-width border-box ${
                  isEditing && "p-12 editing-input-moq"
                }`}
                value={moq}
                onChange={(e) => setMoq(e.target.valueAsNumber)}
                disabled={!isEditing}
              />
            </div>
          )}

          {!isEditing ? (
            <p className="p-12 price cell xetgo-font-tag">
              ₹{priceDetail.price}
            </p>
          ) : (
            <div
              className={`input-main-container p-12 moq cell xetgo-font-tag flex-row ${
                isEditing && "py-17  "
              }`}
            >
              <input
                type="number"
                className={`input-box xetgo-font-tag full-width border-box ${
                  isEditing && "p-12 editing-input-price"
                }`}
                placeholder=" Price"
                value={price}
                onChange={(e) => setPrice(e.target.valueAsNumber)}
              />
            </div>
          )}

          {!isEditing ? (
            <p className="p-12 lead-time cell  xetgo-font-tag">
              {priceDetail.lead_time} Days
            </p>
          ) : (
            <div
              className={`input-main-container p-12 moq cell xetgo-font-tag flex-row ${
                isEditing && "py-17  "
              }`}
            >
              <input
                type="number"
                className={`input-box xetgo-font-tag full-width border-box ${
                  isEditing && "p-12 editing-input-lead-time"
                }`}
                placeholder="Lead time"
                value={lead_time}
                onChange={(e) => setLeadTime(e.target.valueAsNumber)}
              />
            </div>
          )}

          <p className="p-12 date cell xetgo-font-tag">
            {formateDate(priceDetail.created_at)}
          </p>
          {isEditing ? (
            <div className="action-main-container cell flex-row gap-16 p-12 xetgo-font-tag bold">
              <p
                onClick={handleCancle}
                className="cursor-pointer"
                style={{ color: "#5A5A5A" }}
              >
                Cancel
              </p>
              <p onClick={handleSave} className="cursor-pointer">
                Save
              </p>
            </div>
          ) : (
            <div className="action-main-container cell flex-row gap-16  justify-content-center p-12">
              <div
                onClick={() => {
                  setShowHistory(!showHistory);

                  if (!showHistory) {
                    getHistorySkuPricing();
                  }
                }}
                className="flex-row gap-2 xetgo-font-tag cursor-pointer"
              >
                <p className="bold">History</p>
                <img
                  height={16}
                  width={16}
                  className={`${showHistory && "rotated-dropdown-icon"}`}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131100053-chevron-down.svg"
                  alt="drop-icon"
                />
              </div>
              <div className="action-btn-container  flex-row gap-8 ">
                <img
                  onClick={() => {
                    setShowHistory(false);
                    setIsEditing(true);
                  }}
                  className="cursor-pointer"
                  height={14}
                  width={14}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240923113722-pen-square.svg"
                  alt="edit-icon"
                />
                <img
                  onClick={handleDelete}
                  className="cursor-pointer"
                  height={14}
                  width={14}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308111001-trash-2.svg"
                  alt="trash-icon"
                />
              </div>
            </div>
          )}

          {showHistory && priceDetailHistory.length > 0 && (
            <div className="all-history-container ">
              {priceDetailHistory.map((history, index) => (
                <div key={index} className="history-right-grid xetgo-font-tag">
                  <p className="p-12 history-moq cell xetgo-font-tag">
                    {history.moq}
                    {priceEntry.unit}
                  </p>
                  <p className="p-12 history-price cell xetgo-font-tag">
                    ₹{history.price}
                  </p>
                  <p className="p-12 history-lead-time cell xetgo-font-tag">
                    {history.lead_time} Days
                  </p>
                  <p className="p-12 history-date cell xetgo-font-tag">
                    {formateDate(history.created_at)}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SkuPricingTabCard;
