export const STATUS_PROPERTY_MAP: {
  [key: string]: {
    background: string;
    color: string;
  };
} = {
  APPROVED: {
    background: "#E9FFEF",
    color: "#00A81C",
  },
  REJECTED: {
    background: "#FFE6E6",
    color: "#B14242",
  },
  REWORK: {
    background: "#FFEFD3",
    color: "#BA5E08",
  },
  REVIEW: {
    background: "#F9F9DF",
    color: "#A78D06",
  },
};
