import React, { useEffect, useState } from "react";
import "./AddPocPopup.scss";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { ClientPocDetail } from "../../models/SkuListSchema";

interface AddPocPopupProps {
  handleClose: () => void;
  handleNewPocsAdd: (new_pocs_data: ClientPocDetail[]) => void;
  handlePocsUpdate: (poc_data: ClientPocDetail) => void;
  clientId: number;
  update?: boolean;
  pocDetails?: ClientPocDetail;
}

const AddPocPopup: React.FC<AddPocPopupProps> = ({
  handleClose,
  handleNewPocsAdd,
  clientId,
  update,
  pocDetails,
  handlePocsUpdate,
}) => {
  const [allPocs, setAllPocs] = useState<ClientPocDetail[]>([
    { id: -1, name: "", email: "", designation: "", phone: 0 },
  ]);

  useEffect(() => {
    if (update && pocDetails) {
      setAllPocs([
        {
          id: pocDetails.id,
          name: pocDetails.name,
          email: pocDetails.email,
          designation: pocDetails.designation,
          phone: pocDetails.phone,
        },
      ]);
    }
  }, []);

  const handlePocChange = (
    index: number,
    field: keyof ClientPocDetail,
    value: string,
  ) => {
    if (field === "id") return;
    if (field === "phone") {
      const updatedPocs = [...allPocs];
      updatedPocs[index]["phone"] = value;
    }
    const updatedPocs = [...allPocs];
    updatedPocs[index][field] = value;
    setAllPocs(updatedPocs);
  };

  const handleAddPoc = () => {
    setAllPocs([
      ...allPocs,
      { id: -1, name: "", email: "", designation: "", phone: "" },
    ]);
  };

  const handleReset = () => {
    setAllPocs([{ id: -1, name: "", email: "", designation: "", phone: "" }]);
  };

  const handleSaveOrUpdate = () => {
    let isValid = true;
    allPocs.forEach((poc, index) => {
      if (!poc.name || poc.name.trim().length === 0) {
        toast.error(`Please fill name of POC ${index + 1}!`, toastOptions);
        isValid = false;
        return;
      }

      if (!poc.email || poc.email.trim().length === 0) {
        toast.error(`Please fill email of POC ${index + 1}!`, toastOptions);
        isValid = false;
        return;
      }

      if (!poc.designation || poc.designation.trim().length === 0) {
        toast.error(
          `Please fill designation of POC ${index + 1}!`,
          toastOptions,
        );
        isValid = false;
        return;
      }

      if (
        !poc.phone ||
        (typeof poc.phone === "number" && poc.phone <= 0) ||
        poc.phone.toString().length < 10 ||
        poc.phone.toString().length > 10
      ) {
        toast.error(
          `Please fill a valid phone number for POC ${index + 1}!`,
          toastOptions,
        );
        isValid = false;
        return;
      }
    });

    if (isValid && update) {
      handlePocsUpdate(allPocs[0]);
      return;
    }
    if (isValid) {
      handleNewPocsAdd(allPocs);
    }
  };

  return (
    <div className="add-poc-backdrop-main-container flex-row align-items-center justify-content-center">
      <OutsideAlerter className="poc-add-main-container" action={handleClose}>
        <div>
          <div className="add-poc-upper-bar flex-row align-items-center justify-content-space-between py-12 px-16">
            <p className="xetgo-font-button bold">POC Details</p>
            <img
              onClick={handleClose}
              className="cursor-pointer"
              height={8}
              width={8}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
              alt="close-icon"
            />
          </div>

          <div className="all-new-poc-container">
            {allPocs.map((poc, index) => (
              <div key={index} className="flex-column gap-12 py-12 px-16">
                {allPocs.length > 1 && (
                  <p className="xetgo-font-tag bold">POC {index + 1}</p>
                )}
                <div className="flex-row gap-8">
                  <div className="flex-column gap-8 flex-1">
                    <p
                      style={{ color: "#999898" }}
                      className="xetgo-font-tag bold"
                    >
                      Name:<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="input-main-container">
                      <input
                        className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                        placeholder={"Enter Name"}
                        value={poc.name}
                        onChange={(e) =>
                          handlePocChange(index, "name", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-column gap-8 flex-1">
                    <p
                      style={{ color: "#999898" }}
                      className="xetgo-font-tag bold"
                    >
                      Number:<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="input-main-container">
                      <input
                        type="number"
                        className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                        placeholder={"Enter number"}
                        value={(poc && poc.phone) || ""}
                        onChange={(e) =>
                          handlePocChange(index, "phone", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-row gap-8">
                  <div className="flex-column gap-8 flex-1">
                    <p
                      style={{ color: "#999898" }}
                      className="xetgo-font-tag bold"
                    >
                      Email:<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="input-main-container">
                      <input
                        className={`input-box px-12 py-8 border-box full-width xetgo-font-tag `}
                        placeholder={"Enter email"}
                        value={poc.email}
                        onChange={(e) =>
                          handlePocChange(index, "email", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-column gap-8 flex-1">
                    <p
                      style={{ color: "#999898" }}
                      className="xetgo-font-tag bold"
                    >
                      Designation:<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="input-main-container">
                      <input
                        className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                        placeholder={"Enter designation"}
                        value={poc.designation}
                        onChange={(e) =>
                          handlePocChange(index, "designation", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className={`add-poc-modal-btns-container justify-content-space-between border-box flex-row gap-12 new-form-btn-container p-16 ${
              update && "action-btns-end"
            }`}
          >
            {!update && (
              <div
                onClick={handleAddPoc}
                className="poc-add-btn flex-row align-items-center cursor-pointer px-12 py-8 gap-10 xetgo-font-tag"
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                  alt="add-icon"
                />
                <p>Add POC</p>
              </div>
            )}
            <div className="flex-row gap-12 align-items-center">
              {!update ? (
                <div
                  onClick={handleReset}
                  className="cancel-btn xetgo-font-tag"
                >
                  Reset
                </div>
              ) : (
                <div
                  onClick={handleClose}
                  className="cancel-btn xetgo-font-tag"
                >
                  Cancle
                </div>
              )}

              <div
                onClick={handleSaveOrUpdate}
                className="add-btn xetgo-font-tag"
              >
                {update ? "Update" : "Save"}
              </div>
            </div>
          </div>
        </div>
      </OutsideAlerter>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default AddPocPopup;
