import React, { useRef, useState } from "react";
import "./AddVendorSkuPricingPopup.scss";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import Filter from "../oms/Options/Filter/Filter";
import { AddressSchema } from "../../models/SkuListSchema";
import { FilterOptionSchema } from "../../redux/oms/omsTypes";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { useSelector } from "react-redux";
import { selectVendorList } from "../../redux/oms/omsSelectors";

interface AddVendorDropdownProps {
  skuId: number;
  addresses: AddressSchema[];
  onClose: () => void;
  onAddVendors: () => void;
}

interface VendorPrices {
  id: number;
  name: string;
  moq: string;
  price: string;
  leadTime: string;
}

const AddVendorDropdown: React.FC<AddVendorDropdownProps> = ({
  onClose,
  onAddVendors,
  addresses,
  skuId,
}) => {
  const [addressList, setAddressList] = useState<FilterOptionSchema[]>(
    addresses.map((address, index) => ({
      id: address.id,
      name: `${address.address_line}, ${address.city}, ${address.state} ${address.pincode}`,
    })),
  );
  const vendors = useSelector(selectVendorList);
  const [moq, setMoq] = useState("");
  const [price, setPrice] = useState("");
  const [lead_time, setLeadTime] = useState("");
  const [selectedVendors, setSelectedVendors] = useState<FilterOptionSchema[]>(
    [],
  );
  const [selectedAddress, setSelectedAddress] = useState<FilterOptionSchema[]>(
    [],
  );
  const [formData, setFormData] = useState<VendorPrices[]>([]);

  const handleAddVendors = async () => {
    try {
      const form = new FormData();
      if (selectedAddress.length <= 0) {
        toast.error("Please Select Address ", toastOptions);
        return;
      }
      if (selectedVendors.length <= 0) {
        toast.error("Please mention Vendor", toastOptions);
        return;
      }
      form.append("address_id", selectedAddress[0].id.toString());
      form.append("price_list", JSON.stringify(formData));
      const { data } = await ApiService().client.post(
        URLS.SKU.ADD_VENDOR_PRICING(skuId),
        form,
      );
      onAddVendors();
    } catch (error) {
      console.error("Error Adding vendor ", error);
    }
  };

  const handleSelectVendor = (val: FilterOptionSchema[]) => {
    setSelectedVendors(val);
    setFormData(
      val.map((option) => {
        return {
          id: option.id,
          name: option.name,
          moq: "",
          price: "",
          leadTime: "",
        };
      }),
    );
  };

  const updateVendorDetails = (key: string, val: string, id: number) => {
    setFormData((rows) => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, [key]: val };
        }
        return row;
      });
    });
  };

  return (
    <>
      <div className="add-vendor-dialog">
        <div className="add-vendor-dialog-header flex-row align-items-center justify-content-space-between">
          Add Vendor
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
            alt="close"
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="dialog-content flex-column p-16">
          <div className="category-filter flex-column gap-8">
            <span className="filter-heading">
              Shipping address <span style={{ color: "red" }}>*</span>
            </span>
            <Filter
              option={{
                key: "address",
                name:
                  selectedAddress.length > 0 ? "" : "Select Shipping Address",
              }}
              optionList={addressList || []}
              multipleSelection={false}
              onSelection={(val, _option) => {
                if (val.length > 0) {
                  setSelectedAddress(val);
                } else {
                  setSelectedAddress([]);
                }
              }}
              preSelected={selectedAddress}
              mode="showNames"
              borderStyle="solid"
              showPlaceholder={true}
              direction="left"
              addNew={false}
              classname="vendor-category-filter-input"
            />
          </div>
          <div className="category-filter flex-column gap-8">
            <span className="filter-heading">
              Vendor <span style={{ color: "red" }}> *</span>{" "}
            </span>
            <Filter
              option={{
                key: "vendor",
                name: "Select Vendor",
              }}
              optionList={vendors || []}
              multipleSelection={true}
              onSelection={(val, _option) => {
                handleSelectVendor(val);
              }}
              preSelected={[]}
              mode="showNames"
              showPlaceholder={true}
              direction="left"
              borderStyle="solid"
              addNew={false}
              classname="vendor-category-filter-input"
            />
          </div>
          <div className="vendor-editing-value-main-container p-16 flex-column gap-12">
            {formData.map((vendor) => (
              <div
                className="flex-row align-items-center justify-content-space-between"
                key={`vendor-${vendor.id}`}
              >
                <div className="flex-row gap-4 align-items-center">
                  <p
                    style={{
                      background: "#EFEFEF",
                      color: "#484848",
                      borderRadius: "6px",
                    }}
                    className="px-8 py-4 xetgo-font-tag bold"
                  >
                    {vendor.name}
                  </p>
                  <span style={{ color: "#484848" }}>:</span>
                </div>
                <div className="vendor-input-main-container flex-row align-items-center justify-content-space-between gap-6 ">
                  <input
                    className="px-12 py-4 input-box xetgo-font-tag border-box"
                    value={vendor.moq}
                    onChange={(e) =>
                      updateVendorDetails("moq", e.target.value, vendor.id)
                    }
                    placeholder="Enter MOQ"
                  />
                  <input
                    className="px-12 py-4 input-box price xetgo-font-tag border-box"
                    value={vendor.price}
                    onChange={(e) =>
                      updateVendorDetails("price", e.target.value, vendor.id)
                    }
                    placeholder="Enter Price"
                  />
                  <input
                    className="px-12 py-4 input-box lead-time xetgo-font-tag border-box"
                    value={vendor.leadTime}
                    onChange={(e) =>
                      updateVendorDetails("leadTime", e.target.value, vendor.id)
                    }
                    placeholder="Enter Days"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="actionable flex-row align-items-center justify-content-flex-end gap-8 p-16">
          <span
            className="secondary-action xetgo-font-tag cursor-pointer"
            onClick={onClose}
          >
            Cancel
          </span>
          <span
            className="primary-action xetgo-font-tag cursor-pointer"
            style={{
              background: selectedVendors.length > 0 ? "#5151EC" : "#B9B9F8",
            }}
            onClick={handleAddVendors}
          >
            Add Vendor
          </span>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default AddVendorDropdown;
