import React, { useState } from "react";
import { FormArray } from "react-reactive-form";
import { useDispatch } from "react-redux";
import "./AddPurchaseProductDialog.scss";
import {
  AddTaskProductStart,
  addPurchaseProductStart,
} from "../../redux/oms/omsActions";
import { AbstractControl } from "react-reactive-form";
import { PURCHASE_REQUEST_PRODUCT_FORM } from "../../models/PurchaseRequest";
import AddPurchaseProduct from "../AddPurchaseProduct/AddPurchaseProduct";
interface AddPurchaseProductDialogProps {
  handleClose: () => void;
  purchaseRequestId: number;
}

const AddPurchaseProductDialog: React.FC<AddPurchaseProductDialogProps> = ({
  handleClose,
  purchaseRequestId,
}) => {
  const dispatch = useDispatch();
  const [productForm, _setProductForm] = useState<AbstractControl>(
    PURCHASE_REQUEST_PRODUCT_FORM,
  );

  const handleProductSubmit = () => {
    const products = productForm.get("products").value;
    const productData = products.map((val: any) => {
      return {
        ...val,
        name: val.name.name,
        vendor: val.vendor.id,
        product_id: val.name.id,
      };
    });
    const productFormData = new FormData();
    productFormData.append("id", purchaseRequestId.toString());
    productFormData.append("products", JSON.stringify(productData));
    dispatch(addPurchaseProductStart(productFormData));
    resetForm();
    handleClose();
  };

  const closeProductDialog = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    PURCHASE_REQUEST_PRODUCT_FORM.reset();
    const products = productForm.get("products") as FormArray;
    for (let i = 0; i <= products.length - 1; i++) {
      products.removeAt(i);
    }
  };

  return (
    <AddPurchaseProduct
      handleClose={closeProductDialog}
      purchaseRequestId={purchaseRequestId}
    />
  );
};

export default AddPurchaseProductDialog;
