import React, { useEffect, useState } from "react";
import "./AddPurchaseProduct.scss";
import { useDispatch } from "react-redux";
import { AbstractControl, FormArray } from "react-reactive-form";
import {
  MULTI_ADDRESS_FORM,
  NEW_ADDRESS_FORM,
  NEW_PRODUCT_FORM,
} from "../../models/RFQ";
import { FilterOptionSchema } from "../../redux/oms/omsTypes";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { SkuSchema } from "../../models/SkuListSchema";
import Filter from "../oms/Options/Filter/Filter";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

interface AddPurchaseProductProps {
  handleClose: () => void;
  purchaseRequestId: number;
}

interface SkuPrices {
  id: number;
  name: string;
  moq: number;
  price: number;
  leadTime: number;
  quantity: string;
  sku_internal_price_id: number;
  unit: string;
}

interface SkuListDetail {
  lead_time: 0;
  quantity: 0;
  selling_price: 0;
  description: string;
  id: number;
  material: string;
  name: string;
  sku_internal_price_id: number;
  unit: string;
}

const AddPurchaseProduct: React.FC<AddPurchaseProductProps> = ({
  handleClose,
  purchaseRequestId,
}) => {
  const [skuData, setSkuData] = useState<SkuListDetail[]>([]);
  const [skuList, setSkuList] = useState<FilterOptionSchema[]>([]);
  const [selectedSku, setSelectedSku] = useState<FilterOptionSchema[]>([]);
  const [formData, setFormData] = useState<SkuPrices[]>([]);

  useEffect(() => {
    getSkuList();
  }, []);

  const getSkuList = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.PURCHASE_REQUEST.GET_SKU_LIST_WITH_DETAIL(purchaseRequestId),
      );
      const updatedSkuList = data.data.map((sku: SkuSchema, index: number) => ({
        id: sku.id,
        name: sku.name + ", " + sku.description,
      }));
      setSkuData(data.data);
      setSkuList(updatedSkuList);
    } catch (error) {
      console.error("Error fetching quotes:", error);
      return [];
    }
  };

  const handleProductSubmit = async () => {
    if (formData.length === 0) {
      toast.error("Please Select SKU!", toastOptions);
      return;
    }

    const hasInvalidQuantity = formData.some((data, index) => {
      if (!data.quantity) {
        toast.error(
          `Please Enter quantity of Selected SKU ${index + 1}!`,
          toastOptions,
        );
        return true;
      }
      return false;
    });

    if (hasInvalidQuantity) {
      return;
    }

    const dataToSend = selectedSku.map((sku, index) => {
      const matchedSkuData = skuData.find((skuD) => skuD.id === sku.id);

      return {
        sku_id: sku.id,
        quantity: formData[index].quantity,
        sku_internal_price_id: matchedSkuData?.sku_internal_price_id,
      };
    });

    try {
      const { data } = await ApiService().client.post(
        URL.PURCHASE_REQUEST.SKU_ADD_PRODUCT(purchaseRequestId),
        {
          sku_data: dataToSend,
        },
      );
      handleClose();
    } catch (error) {
      console.error("Error submitting product data:", error);
    }
  };

  const closeProductDialog = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleClose();
  };

  const handleSelectedSkus = (val: FilterOptionSchema[]) => {
    setSelectedSku(val);

    setFormData(
      val.map((option) => {
        const matchedSku = skuData.find((sku) => sku.id === option.id);

        return {
          id: option.id,
          name: option.name,
          moq: matchedSku!.quantity,
          price: matchedSku!.selling_price,
          leadTime: matchedSku!.lead_time,
          sku_internal_price_id: matchedSku!.sku_internal_price_id,
          quantity: "",
          unit: matchedSku!.unit,
        };
      }),
    );
  };

  const updateFormSkuDetails = (key: string, val: number, id: number) => {
    setFormData((rows) => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, [key]: val };
        }
        return row;
      });
    });
  };
  return (
    <div className="add-purchase-product-popup-container ">
      <div className="heading-bar flex-row align-items-center justify-content-space-between px-16 py-12">
        <p className="xetgo-font-button bold">Add Product</p>
        <img
          className="cursor-pointer"
          onClick={closeProductDialog}
          height={16}
          width={16}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
          alt="close-icon"
        />
      </div>
      <div className="add-sku-form-section flex-column gap-16 px-16 py-12">
        <div className="sku-main-container flex-column gap-8 xetgo-font-tag">
          <p>
            SKU <span style={{ color: "red" }}>*</span>
          </p>
          <Filter
            option={{
              key: "sku",
              name: "Select Sku",
            }}
            optionList={skuList}
            multipleSelection={true}
            onSelection={(val, option) => {
              handleSelectedSkus(val);
            }}
            mode="showNames"
            showPlaceholder={false}
            borderStyle="solid"
            preSelected={selectedSku}
            addNew={false}
            classname="custom-filter"
          />
        </div>
        {formData.length === 0 && (
          <div className="empty-state-sku flex-1 flex-column align-items-center justify-content-center">
            <img
              height={31}
              width={30}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808061403-Document+add.svg"
              alt="add-file-icon"
            />
            <p className="text xetgo-font-button bold">
              Please Select Skus to Proceed Further.{" "}
            </p>
          </div>
        )}

        {formData.length > 0 && (
          <div className="all-sku-detail-form-data-main-container p-12 flex-column gap-4 ">
            {formData.map((sku) => (
              <div
                className="flex-row align-items-center justify-content-space-between"
                key={`sku-${sku.id}`}
              >
                <div className="flex-row gap-4 align-items-center">
                  <p
                    style={{
                      background: "#EFEFEF",
                      color: "#484848",
                      borderRadius: "6px",
                    }}
                    className="px-8 py-4 xetgo-font-tag bold"
                  >
                    {sku.name}
                  </p>
                  <span style={{ color: "#484848" }}>:</span>
                </div>
                <div className="sku-input-main-container flex-row align-items-center justify-content-space-between gap-6 ">
                  <p className="px-12 py-4 input-box xetgo-font-tag border-box">
                    {sku.moq ? `${sku.moq} ${sku.unit}` : "--"}
                  </p>

                  <p className="px-12 py-4 input-box price xetgo-font-tag border-box">
                    {sku.price ? `₹ ${sku.price}` : "--"}
                  </p>

                  <p className="px-12 py-4 input-box lead-time xetgo-font-tag border-box">
                    {sku.leadTime
                      ? `${
                          Number(sku.leadTime) <= 1
                            ? `${sku.leadTime} Day`
                            : `${sku.leadTime} Days`
                        } `
                      : "--"}
                  </p>
                  <input
                    type="number"
                    className="px-12 py-4 input-box quantity xetgo-font-tag border-box bolder"
                    value={sku.quantity}
                    onChange={(e) =>
                      updateFormSkuDetails(
                        "quantity",
                        e.target.valueAsNumber,
                        sku.id,
                      )
                    }
                    placeholder="Quantity.."
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="add-sku-popup-modal-btns-container justify-content-end flex-row gap-12 new-form-btn-container p-16">
        <div
          onClick={(e) => closeProductDialog(e)}
          className="cancel-btn xetgo-font-tag"
        >
          Cancel
        </div>
        <div
          onClick={handleProductSubmit}
          style={{
            background: `${formData.length > 0 ? "#5151ec" : "#a3a3ff"} `,
            border: `${formData.length === 0 ? "none" : "1px solid #5151ec"}`,
          }}
          className="add-btn xetgo-font-tag"
        >
          Add
        </div>
      </div>
    </div>
  );
};

export default AddPurchaseProduct;
