import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DeliveryDetailQualityCheckContent from "../../components/DeliveryDetailQualityCheckContent/DeliveryDetailQualityCheckContent";
import DeliveryDetailTabContent from "../../components/DeliveryDetailTabContent/DeliveryDetailTabContent";
import SideNav from "../../components/shared/sidenav/SideNav";
import { IDeliveryDetail } from "../../models/DeliveryDetail";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import "./DeliveryDetail.scss";

const DeliveryDetail: React.FC = () => {
  const { id } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [deliveryDetails, setDeliveryDetails] = useState<IDeliveryDetail>();

  useEffect(() => {
    getDeliveryDetails(Number(id)).then((r) => r);
  }, [id]);

  const getDeliveryDetails = async (id: number) => {
    try {
      const { data } = await ApiService().client.get(
        URL.DELIVERY_DETAILS.GET_DELIVERY_DETAILS(id),
      );
      setDeliveryDetails(data.data);
    } catch (error) {
      console.error("Error fetching Delivery Details:", error);
    }
  };

  return (
    <div className="page-wrapper flex-row">
      <SideNav />

      <div className="delivery-details-page">
        <div className="delivery-details-page-header xetgo-font-tag px-16 py-12">
          <span style={{ color: "#AA5200" }}> Delivery </span>
          <span style={{ color: "#757575" }}> / {id} </span>
        </div>

        <div className="delivery-details-page-body p-16">
          <div className="delivery-page-tabs-container flex-row align-items-center gap-16">
            <div
              className={`delivery-page-tab px-12 py-8 xetgo-font-button-bold flex-row align-items-center gap-4 ${
                activeTabIndex === 0 && "active"
              }`}
              onClick={() => setActiveTabIndex(0)}
            >
              <img
                src={
                  activeTabIndex === 0
                    ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241004080554-truck-purple.svg"
                    : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241004080554-truck-brown.svg"
                }
              />
              Delivery Details
            </div>

            <div
              className={`delivery-page-tab px-12 py-8 xetgo-font-button-bold flex-row align-items-center gap-4 ${
                activeTabIndex === 1 && "active"
              }`}
              onClick={() => setActiveTabIndex(1)}
            >
              <img
                src={
                  activeTabIndex === 1
                    ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241004080554-check-circle-purple.svg"
                    : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241004080554-check-circle-brown.svg"
                }
              />
              Quality Check Details
            </div>
          </div>

          {activeTabIndex === 0 && deliveryDetails && (
            <DeliveryDetailTabContent
              purchased_product_delivery_schedule_id={Number(id)}
              deliveryDetails={deliveryDetails}
            />
          )}
          {activeTabIndex === 1 && (
            <DeliveryDetailQualityCheckContent
              purchased_product_delivery_schedule_id={Number(id)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetail;
