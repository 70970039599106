import React, { useEffect, useState } from "react";
import { TaskFileSchema, TaskStatusColorSchema } from "../../../models/Task";
import { Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../../utils/toast";
import { useDispatch } from "react-redux";
import OutsideAlerter from "../../../utils/OutsideClickDetector";
import AssigneeSelector from "../AssigneeSelector/AssigneeSelector";
import POCDetails from "../POCDetails/POCDetails";
import { useSelector } from "react-redux";
import Loader from "../../home/loader/Loader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  fetchFilter,
  purchaseOrderDetail,
  updatePurchaseOrderDetailStart,
} from "../../../redux/oms/omsActions";
import XetImage from "../../shared/xet-image/XetImage";
import SideNav from "../../shared/sidenav/SideNav";
import { TASK_PRIORITY } from "../../../models/RFQ";
import {
  selectPurchaseOrderDetail,
  selectStatusAllowed,
} from "../../../redux/oms/omsSelectors";
import "./PurchaseOrderDetail.scss";
import ApiService from "../../../services/apiService";
import URL from "../../../urls/Urls";
import XetModal from "../../shared/xet-modal/XetModal";
import SampleDialog from "../../SampleDialog/SampleDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import XetEditor from "../../shared/xet-editor/XetEditor";
import { BADGES } from "./purchaseOrderDetail.data";
import AddPurchaseProductDialog from "../../AddPurchaseProductDialog/AddPurchaseProductDialog";
import PurchaseOrderProducts from "../PurchaseOrderProducts/PurchaseOrderProducts";
import TaskFiles from "../TaskFiles/TaskFiles";
import TaskComment from "../TaskComment/TaskComment";

const PurchaseOrderDetail = () => {
  const dispatch = useDispatch();
  const { id, category, vendor_id } = useParams();
  const navigate = useNavigate();
  const details = useSelector(selectPurchaseOrderDetail);
  const statusAllowed = useSelector(selectStatusAllowed);
  const [colorSchema, setColorSchema] = useState<{
    background: string;
    color: string;
  }>();
  const [descriptionActive, setDescriptionActive] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [descriptionTracker, setDescriptionTracker] = useState<string>("");
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [sampleDialog, setSampleDialog] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [files, setFiles] = useState<TaskFileSchema[]>([]);
  const [openAssigneeSelection, setOpenAssigneeSelection] =
    useState<boolean>(false);

  const [openTaskTracker, setOpenTaskTracker] = useState(false);
  const [openPOCDetails, setOpenPOCDetails] = useState<boolean>(false);
  const [openPriority, setOpenPriority] = useState<boolean>(false);
  const [taskCategoryStatuses, setTaskCategoryStatuses] = useState<
    { name: string; status: string }[]
  >([]);

  const sales = details?.assignees?.filter((user) => user.role === "SALES");
  const sourcing = details?.assignees?.filter(
    (user) => user.role === "SOURCING",
  );
  const operation = details?.assignees?.filter(
    (user) => user.role === "OPERATIONS",
  );

  useEffect(() => {
    if (details?.assets) {
      setFiles(details.assets);
    }
  }, [details?.assets]);

  useEffect(() => {
    dispatch(fetchFilter("XeTrack"));
    if (id) {
      dispatch(
        purchaseOrderDetail({
          id: parseInt(id, 10),
          category: category || "",
          vendor_id,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (details) {
      setColorSchema(TaskStatusColorSchema[details.status]);
      setDescription(details.description);
      setDescriptionTracker(details.description);
    }
  }, [details]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        navigate(`/eqs/${category}`);
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [category]);

  const descriptionChangeHandler = (data: string) => {
    if (!descriptionActive) {
      setDescriptionActive(true);
    }
    setDescriptionTracker(data);
  };

  const saveDescription = async () => {
    if (details) {
      dispatch(
        updatePurchaseOrderDetailStart({
          id: details.id,
          description: descriptionTracker,
          category: category || "",
        }),
      );
      setDescription(descriptionTracker);
      setDescriptionActive(false);
      toast.success("Description updated successfully!", toastOptions);
    }
  };

  const toggleStatus = () => {
    if (details) {
      if ((statusAllowed[details.status] || []).length > 0) {
        setStatusDropdown((old) => !old);
      }
    }
  };

  const handleSampleClose = () => {
    setSampleDialog(false);
  };

  const updatestatus = (id: number, status: string) => {
    if (details) {
      dispatch(
        updatePurchaseOrderDetailStart({
          id,
          status,
          category: category || "",
        }),
      );
      setStatusDropdown(false);
      toast.success("Status updated successfully!", toastOptions);
    }
  };

  const updateTaskStatus = (id: number, status: string) => {
    if (status == "Samples") {
      setSampleDialog(true);
      return;
    }
    updatestatus(id, status);
  };

  const updateTaskPriority = (priority: string) => {
    if (details) {
      dispatch(
        updatePurchaseOrderDetailStart({
          id: details.id,
          priority,
          category: category || "",
        }),
      );
      setOpenPriority(false);
      toast.success("Priority updated successfully!", toastOptions);
    }
  };

  const handleDueDateChange = async (dueDate: string | undefined) => {
    if (details) {
      dispatch(
        updatePurchaseOrderDetailStart({
          id: details.id,
          due_date: dueDate,
          category: category || "",
        }),
      );
      toast.success("Due Date updated successfully!", toastOptions);
    }
  };

  const toggleTaskStatusTracker = async () => {
    if (details?.task_id && !openTaskTracker) {
      const { data } = await ApiService().client.get(
        URL.OMS.GET_TASK_CATEGORY_STATUSES(details?.task_id),
      );
      setTaskCategoryStatuses(data.status_list);
    }
    setOpenTaskTracker((old) => !old);
  };

  const handleAddressLocation = (link: string | undefined) => {
    window.open(link, "_blank");
  };

  const deleteFile = async (file: TaskFileSchema) => {
    await ApiService().client.delete(
      URL.OMS.DELETE_PURCHASE_ORDER_FILE(file.id),
    );
    setFiles((oldFiles) => oldFiles.filter((row) => row.id != file.id));
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tagValue: string,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const formData = new FormData();

      formData.append("category", tagValue);
      files.forEach((file, index) => {
        formData.append("files[]", file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          formData.append("displayNames[]", displayName);
        }
      });
      try {
        const param = Number(details?.purchase_request_id);
        const res = await ApiService().client.post(
          URL.OMS.UPLOAD_PURCHASE_ORDER_FILE(param),
          formData,
        );
        setFiles((oldFiles) => [...oldFiles, ...res.data]);
        toast.success("Upload successful!", toastOptions);
      } catch (error) {
        console.error(error);
        toast.error("Upload failed. Please try again.", toastOptions);
      }
    }
  };

  const formatDate = (actualDelivery: string) => {
    const date = new Date(actualDelivery);

    const options = {
      day: "2-digit" as const,
      month: "2-digit" as const,
      year: "numeric" as const,
    };

    const formattedDate = date.toLocaleDateString("en-IN", options);
    return formattedDate.replace(/\//g, "-");
  };

  return (
    <div className="flex-row position-relative">
      <SideNav></SideNav>
      <div className="border-box flex-1 task-detail-section">
        {details == null && <Loader />}
        {details && (
          <>
            <div className="task-details-container">
              <div className="task-title-container full-width flex-row justify-content-space-between align-items-center p-16 border-box">
                <div className="flex-row gap-16 align-items-center flex-1">
                  <p className="purchase-order-title xetgo-font-h3">
                    {details.client_detail.name}
                  </p>
                  <div className="po-number-container py-4 px-12">
                    <p className="xetgo-font-button-bolder">
                      {details.po_number}
                    </p>
                  </div>
                  <div className="total-deal-value-container px-8 py-4 flex-row gap-12">
                    <p className="xetgo-font-tag" style={{ color: "#636363" }}>
                      Est. Deal Value
                    </p>
                    <div className="m-0 xetgo-font-tag deal-value">
                      {details.po_value}
                    </div>
                    <p
                      className="xetgo-font-tag bolder"
                      style={{ color: "#484848" }}
                    >
                      ₹
                    </p>
                  </div>
                </div>
                <div className="flex-row gap-32">
                  <OutsideAlerter
                    action={() => setOpenAssigneeSelection(false)}
                  >
                    <div className="flex-column gap-2">
                      <p className="title xetgo-font-tag field-key">
                        Assignees
                      </p>
                      <div
                        className="flex-row justify-content-flex-end position-relative cursor-pointer"
                        onClick={() => setOpenAssigneeSelection(true)}
                      >
                        {sales &&
                          sales.map((user) => {
                            return (
                              <XetImage
                                className="assignees"
                                src={user.avatar}
                                alt={user.name}
                                width={22}
                                height={22}
                                key={user.id}
                              />
                            );
                          })}
                        {operation &&
                          operation.map((user) => {
                            return (
                              <XetImage
                                className="assignees"
                                src={user.avatar}
                                alt={user.name}
                                width={22}
                                height={22}
                                key={user.id}
                              />
                            );
                          })}
                        {openAssigneeSelection && (
                          <AssigneeSelector
                            task_id={details.id}
                            assigneeList={details.assignees}
                            roles={["SALES", "OPERATIONS"]}
                            type={"XeTrack"}
                          />
                        )}
                      </div>
                    </div>
                  </OutsideAlerter>
                  {category !== "sourcing" && (
                    <OutsideAlerter action={() => setOpenPOCDetails(false)}>
                      <div className="flex-column justify-content-center align-items-center rfq-client">
                        <p className="xetgo-font-tag field-key">Client</p>
                        <div
                          className="xetgo-font-tag flex-row justify-content-center align-items-center gap-4"
                          onClick={() => setOpenPOCDetails(true)}
                        >
                          <span
                            className={`client-type-pill ${
                              details.client_detail.client_type == "NEW"
                                ? "new"
                                : "existing"
                            }`}
                          >
                            {details.client_detail.client_type.toLowerCase()}
                          </span>
                          <Tooltip title={details.client_detail.name}>
                            <span>
                              <XetImage
                                className="assignees"
                                src={details.client_detail.avatar}
                                alt={details.client_detail.name}
                                width={22}
                                height={22}
                              />
                            </span>
                          </Tooltip>
                        </div>
                        {openPOCDetails && (
                          <POCDetails
                            id={details.id}
                            pocs={details.pocs}
                            client_detail={details.client_detail}
                            viewOnly={true}
                          />
                        )}
                      </div>
                    </OutsideAlerter>
                  )}
                </div>
              </div>
              <div className="task-content-section flex-column border-box justify-content-space-between gap-16">
                <div className="flex-column gap-16">
                  <div className="task-info-container flex-row px-16 py-4 justify-content-space-between">
                    <div className="flex-row align-items-center gap-20">
                      <OutsideAlerter action={() => setStatusDropdown(false)}>
                        <div className="flex-row gap-2 status-container">
                          <div
                            className="status px-8 py-6 xetgo-font-tag-bold cursor-pointer"
                            style={{
                              background: colorSchema?.background,
                              color: colorSchema?.color,
                            }}
                            onClick={toggleStatus}
                          >
                            {details.status}
                          </div>
                          {statusDropdown && (
                            <div className="p-8 flex-column status-dropdown">
                              {(statusAllowed[details.status] || []).map(
                                (val) => {
                                  return (
                                    <div
                                      className="option flex-row gap-8 px-12 py-8 xetgo-font-tag-bold align-items-center"
                                      key={val}
                                      onClick={() =>
                                        updateTaskStatus(details.id, val)
                                      }
                                    >
                                      <div
                                        className="round-bg"
                                        style={{
                                          backgroundColor:
                                            TaskStatusColorSchema[val]
                                              .background,
                                        }}
                                      ></div>
                                      {val}
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          )}
                        </div>
                      </OutsideAlerter>
                      <OutsideAlerter
                        action={() => setOpenTaskTracker(false)}
                        className="task-tracker-container flex-row align-items-center position-relative"
                      >
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231121092301-Progress.svg"
                          alt="tracker"
                          height={22}
                          width={22}
                          className="cursor-pointer"
                          onClick={toggleTaskStatusTracker}
                        />
                        {openTaskTracker && (
                          <div className="flex-column gap-10 px-12 py-8 tracker-popup">
                            {taskCategoryStatuses.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  className="tracker-option flex-row justify-content-space-between align-items-center"
                                >
                                  <p className="xetgo-font-tag-bold">
                                    {"//"} {val.name} {"//"}
                                  </p>
                                  <div
                                    className="status px-8 py-6 xetgo-font-tag-bold"
                                    style={{
                                      background:
                                        TaskStatusColorSchema[val.status]
                                          ?.background,
                                      color:
                                        TaskStatusColorSchema[val.status]
                                          ?.color,
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {val.status}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </OutsideAlerter>
                      <div className="flex-column gap-2">
                        <p className="title xetgo-font-tag field-key">
                          Task due on
                        </p>
                        <div className="due-value xetgo-font-tag">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={dayjs(
                                details.due_date_formatted
                                  .split("-")
                                  .reverse()
                                  .join("-"),
                              )}
                              disablePast
                              format={"DD-MM-YYYY"}
                              views={["year", "month", "day"]}
                              onChange={(event) =>
                                handleDueDateChange(
                                  event?.toDate().toISOString(),
                                )
                              }
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="flex-column justify-content-center">
                        <p className="xetgo-font-tag field-key">
                          Task created by
                        </p>
                        <p
                          className="xetgo-font-tag"
                          style={{ color: "#bcbcbc" }}
                        >
                          <span
                            className="xetgo-font-tag-bold"
                            style={{ color: "#484848" }}
                          >
                            {details.creator_name}{" "}
                          </span>
                          on {details.start_date_formatted}
                        </p>
                      </div>
                      <div className="flex-column justify-content-center">
                        <div className="flex-row gap-4">
                          <img
                            onClick={() =>
                              handleAddressLocation(
                                details?.delivery_address?.map_link,
                              )
                            }
                            className="pod-location-icon cursor-pointer"
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228215913-location-outline.svg"
                            alt="location icon"
                          />
                          <p className="xetgo-font-tag field-key">
                            Delivery at
                          </p>
                        </div>
                        <p
                          className="xetgo-font-tag flex-row gap-4 align-items-end"
                          style={{
                            color: "#bcbcbc",
                            paddingLeft: "18px",
                          }}
                        >
                          <span
                            className="xetgo-font-tag-bold ellipsis"
                            style={{ color: "#484848", maxWidth: "400px" }}
                          >
                            {details.delivery_address?.address_line}
                          </span>
                          , {details.delivery_address?.city},{" "}
                          {details.delivery_address?.country}
                        </p>
                      </div>
                    </div>
                    <div className="flex-row gap-4 align-items-center">
                      {/* <p style={{if details.priority == HIGH then red color if medium then blue and if low then grey}} className="xetgo-font-tag">{details.priority}</p> */}
                      <p
                        style={{
                          color:
                            details.priority === "HIGH"
                              ? "#FF0000"
                              : details.priority === "MEDIUM"
                              ? "blue"
                              : details.priority === "LOW"
                              ? "grey"
                              : "black", // Default color if priority doesn't match any of the conditions
                        }}
                        className="xetgo-font-tag"
                      >
                        {details.priority}
                      </p>
                      <OutsideAlerter action={() => setOpenPriority(false)}>
                        <div className="priority-container flex-row align-items-center">
                          <img
                            className="priority-flag cursor-pointer"
                            src={
                              details.priority === TASK_PRIORITY[0].name
                                ? TASK_PRIORITY[0].avatar
                                : details.priority === TASK_PRIORITY[1].name
                                ? TASK_PRIORITY[1].avatar
                                : TASK_PRIORITY[2].avatar
                            }
                            alt="priority"
                            onClick={() => setOpenPriority((old) => !old)}
                          />
                          {openPriority && (
                            <div className="p-8 flex-column priority-dropdown">
                              {TASK_PRIORITY.map((row, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => updateTaskPriority(row.name)}
                                    className="option flex-row gap-8 px-12 py-8 xetgo-font-tag-bold align-items-center"
                                  >
                                    <img
                                      src={row.avatar}
                                      width={16}
                                      alt={row.name}
                                    />
                                    {row.name}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </OutsideAlerter>
                    </div>
                  </div>
                  <div className="px-16">
                    <XetEditor
                      value={description}
                      defaultHeight="248px"
                      onChange={descriptionChangeHandler}
                      showSaveContent={descriptionActive}
                      onSaveContent={saveDescription}
                    />
                  </div>
                </div>
                <div>
                  <PurchaseOrderProducts
                    productDetails={details.product_details}
                    purchaseRequestId={details.purchase_request_id}
                    poNumber={details.po_number}
                    taskId={details.id}
                    salesAssignee={details.assignees}
                  />
                  <TaskFiles
                    fileList={files}
                    handleFileUpload={handleFileUpload}
                    deleteFile={deleteFile}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {details && (
        <XetModal
          backdropClose={false}
          open={openAddProduct}
          handleClose={() => setOpenAddProduct(false)}
        >
          <AddPurchaseProductDialog
            handleClose={() => setOpenAddProduct(false)}
            purchaseRequestId={details.purchase_request_id}
          />
        </XetModal>
      )}

      {details && (
        <XetModal
          backdropClose={true}
          open={sampleDialog}
          handleClose={handleSampleClose}
        >
          <SampleDialog
            handleClose={handleSampleClose}
            taskId={details.id}
            onSampleFormSubmit={updatestatus}
          />
        </XetModal>
      )}
      <ToastContainer position="top-right" />
    </div>
  );
};

export default PurchaseOrderDetail;
