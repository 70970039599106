import React, { useEffect, useState } from 'react';
import ApiService from '../../services/apiService';
import URL from '../../urls/Urls';
import Filter from '../oms/Options/Filter/Filter';
import { useSelector } from 'react-redux';
import { selectVendorList } from '../../redux/oms/omsSelectors';
import { FilterOptionSchema } from '../../redux/oms/omsTypes';
import { SkuSchema } from '../../models/SkuListSchema';
import './AssignVendorDialog.scss';

interface AssignVendorDialogProps {
  handleClose: () => void;
  purchaseRequestId: number;
}

interface SkuPrices {
  id: number;
  name: string;
  price: string;
  quantity: string;
  prpm_id: number;
  sivm: number;
}

interface VendorSkuGroup {
  vendor: FilterOptionSchema | null;
  orderNo: string;
  selectedSku: FilterOptionSchema[];
  skuFormData: SkuPrices[];
}

const AssignVendorDialog: React.FC<AssignVendorDialogProps> = ({
  handleClose,
  purchaseRequestId,
}) => {
  const [vendors, setVendors] = useState([]);
  const [skuPrpmMap, setSkuPrpmMap] = useState<{ [key: number]: any[] }>({});
  const [skuVendorMap, setSkuVendorMap] = useState<{ [key: number]: any[] }>(
    {}
  );
  const [skuList, setSkuList] = useState<FilterOptionSchema[]>([]);
  const [vendorSkuGroups, setVendorSkuGroups] = useState<VendorSkuGroup[]>([
    {
      vendor: null,
      orderNo: '',
      selectedSku: [],
      skuFormData: [],
    },
  ]);

  useEffect(() => {
    getSkuList();
  }, []);

  const getSkuList = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.OMS.SKU_VENDOR_DETAILS(purchaseRequestId)
      );
      if (data) {
        setSkuList(data.skus);
        setVendors(data.vendors);
        setSkuPrpmMap(data.sku_to_prpm);
        setSkuVendorMap(data.sku_to_sivm);
      }
    } catch (error) {
      console.error('Error fetching SKUs:', error);
    }
  };

  const handleSelectVendor = (groupIndex: number, val: FilterOptionSchema) => {
    const updatedGroups = [...vendorSkuGroups];
    updatedGroups[groupIndex].vendor = val;
    setVendorSkuGroups(updatedGroups);
  };

  const handleOrderNoChange = (groupIndex: number, val: string) => {
    const updatedGroups = [...vendorSkuGroups];
    updatedGroups[groupIndex].orderNo = val;
    setVendorSkuGroups(updatedGroups);
  };

  const handleSelectedSkus = (
    groupIndex: number,
    val: FilterOptionSchema[]
  ) => {
    const updatedGroups = [...vendorSkuGroups];
    updatedGroups[groupIndex].selectedSku = val;
    updatedGroups[groupIndex].skuFormData = val.map((option) => {
      const sivm = skuVendorMap[option.id].filter(
        (row) => row.vendor_id === updatedGroups[groupIndex].vendor?.id
      )[0];
      const prpm = skuPrpmMap[option.id][0];
      return {
        id: option.id,
        name: option.name,
        price: sivm.price,
        quantity: prpm.quantity,
        prpm_id: prpm.id,
        sivm: sivm.id,
      };
    });
    setVendorSkuGroups(updatedGroups);
  };

  const updateFormSkuDetails = (
    groupIndex: number,
    key: string,
    val: number,
    id: number
  ) => {
    const updatedGroups = [...vendorSkuGroups];
    updatedGroups[groupIndex].skuFormData = updatedGroups[
      groupIndex
    ].skuFormData.map((row) => (row.id === id ? { ...row, [key]: val } : row));
    setVendorSkuGroups(updatedGroups);
  };

  const handleAddNewVendor = () => {
    setVendorSkuGroups([
      ...vendorSkuGroups,
      {
        vendor: null,
        orderNo: '',
        selectedSku: [],
        skuFormData: [],
      },
    ]);
  };

  const handleProductSubmit = async () => {
    const formData = new FormData();
    const data: {
      prpm_id: number;
      vendor_po_number: string;
      sivm_id: number;
    }[] = [];
    vendorSkuGroups.forEach((row) => {
      row.skuFormData.forEach((entry) => {
        data.push({
          prpm_id: entry.prpm_id,
          vendor_po_number: row.orderNo,
          sivm_id: entry.sivm,
        });
      });
    });
    formData.append('data', JSON.stringify(data));
    await ApiService().client.post(
      URL.PURCHASE_REQUEST.ASSIGN_VENDOR(purchaseRequestId),
      formData
    );
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setVendorSkuGroups([
      {
        vendor: null,
        orderNo: '',
        selectedSku: [],
        skuFormData: [],
      },
    ]);
  };

  const getSkuListForVendor = (index: number) => {
    const vendor_id = vendorSkuGroups[index].vendor?.id;
    if (vendor_id == null) {
      return [];
    }
    return skuList.filter((row) => {
      return (
        skuVendorMap[row.id] &&
        skuVendorMap[row.id].filter((row) => row.vendor_id === vendor_id)
          .length > 0
      );
    });
  };

  return (
    <div className="assign-vendor-popup-container flex-column">
      <div className="heading-bar flex-row align-items-center justify-content-space-between px-16 py-12">
        <p className="xetgo-font-button bold">Assign Vendor</p>
        <img
          className="cursor-pointer"
          onClick={handleClose}
          height={16}
          width={16}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
          alt="close-icon"
        />
      </div>

      <div className="allgroup-wrapper">
        {vendorSkuGroups.map((group, index) => (
          <div
            key={index}
            className="add-sku-form-section flex-column gap-16 px-16 py-12"
          >
            {vendorSkuGroups.length > 1 && (
              <p className="xetgo-font-button bold">Vendor {index + 1}</p>
            )}
            <div className="vendor-order-main-container flex-row gap-20">
              <div className="flex-column gap-8 xetgo-font-tag flex-1">
                <p className="bold">Vendor</p>
                <Filter
                  option={{ key: 'vendor', name: 'Select Vendor' }}
                  optionList={vendors || []}
                  multipleSelection={false}
                  onSelection={(val) => handleSelectVendor(index, val[0])}
                  preSelected={group.vendor ? [group.vendor] : []}
                  mode="showNames"
                  showPlaceholder={false}
                  direction="left"
                  borderStyle="solid"
                  addNew={false}
                  classname="custom-filter"
                />
              </div>
              <div className="flex-column gap-8 xetgo-font-tag flex-1">
                <p className="bold">Order Number:</p>
                <input
                  className="order-input-box xetgo-font-tag px-12 py-8"
                  onChange={(e) => handleOrderNoChange(index, e.target.value)}
                  value={group.orderNo}
                  placeholder="Enter No."
                />
              </div>
            </div>

            <div className="sku-main-container flex-column gap-8 xetgo-font-tag">
              <p>
                SKU <span style={{ color: 'red' }}>*</span>
              </p>
              <Filter
                option={{ key: 'sku', name: 'Select Sku' }}
                optionList={getSkuListForVendor(index)}
                multipleSelection={true}
                onSelection={(val) => handleSelectedSkus(index, val)}
                mode="showNames"
                showPlaceholder={false}
                borderStyle="solid"
                preSelected={group.selectedSku}
                addNew={false}
                classname="custom-filter"
              />
            </div>

            {group.skuFormData.length > 0 && (
              <div className="all-sku-detail-form-data-main-container p-12 flex-column gap-4">
                {group.skuFormData.map((sku) => (
                  <div
                    className="sku-input-main-container flex-row align-items-center justify-content-space-between"
                    key={`sku-${sku.id}`}
                  >
                    <div className="flex-row gap-2 align-items-center">
                      <p
                        className="px-8 py-4 xetgo-font-tag bold"
                        style={{
                          background: '#EFEFEF',
                          color: '#484848',
                          borderRadius: '6px',
                        }}
                      >
                        {sku.name}
                      </p>
                      <span style={{ color: '#484848' }}>:</span>
                    </div>
                    <div className="flex-row gap-8">
                      <input
                        type="number"
                        className="px-12 py-4 input-box price xetgo-font-tag border-box"
                        value={sku.price}
                        onChange={(e) =>
                          updateFormSkuDetails(
                            index,
                            'price',
                            e.target.valueAsNumber,
                            sku.id
                          )
                        }
                        placeholder="Enter Price"
                      />
                      <input
                        type="number"
                        className="px-12 py-4 input-box quantity xetgo-font-tag border-box"
                        value={sku.quantity}
                        onChange={(e) =>
                          updateFormSkuDetails(
                            index,
                            'quantity',
                            e.target.valueAsNumber,
                            sku.id
                          )
                        }
                        placeholder="Enter Quantity"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="assign-vendor-popup-modal-btns-container justify-content-space-between align-items-center flex-row gap-12 new-form-btn-container p-16">
        <div
          onClick={handleAddNewVendor}
          className="poc-add-btn flex-row align-items-center cursor-pointer px-12 py-8 gap-10 xetgo-font-tag"
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
            alt="add-icon"
          />
          <p style={{ color: '#5151EC' }}> Add new vendor</p>
        </div>
        <div className="flex-row gap-12 align-items-center">
          <div onClick={handleClose} className="cancel-btn xetgo-font-tag">
            Cancel
          </div>
          <div
            onClick={handleProductSubmit}
            className={`add-btn xetgo-font-tag ${
              vendorSkuGroups.length === 0 ? 'disable' : ''
            }`}
          >
            Assign Vendor
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignVendorDialog;
