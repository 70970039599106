import environment from "../environments/environment";

const URL = {
  USERS: {
    GOOGLE_LOGIN: `${environment.baseUrl}/auth/google_login`,
    GET_USER: `${environment.baseUrl}/auth/user/authenticated`,
    LOGOUT: `${environment.baseUrl}/auth/logout`,
    NEW: `${environment.baseUrl}/auth/new`,
    NEW_ADDRESS: (id: number) => `${environment.baseUrl}/auth/address/${id}`,
    FETCH_ADDRESS: (id: number) => `${environment.baseUrl}/auth/address/${id}`,
    FETCH_NOTIFICATIONS: `${environment.baseUrl}/notifications`,
    FETCH_NOTIFICATION_PREFERENCES: `${environment.baseUrl}/notification_preferences`,
    UPDATE_NOTIFICATION_PREFERENCE: `${environment.baseUrl}/update/notification_preferences`,
    UPDATE_NOTIFICATION_STATUS: (id: number) =>
      `${environment.baseUrl}/notification/${id}`,
  },

  FILES: {
    UPLOAD_FILE: `${environment.baseUrl}/files/uploads`,
    GET_FILES: `${environment.baseUrl}/files`,
    DELETE_FILE: (id: number) => `${environment.baseUrl}/files/delete/${id}`,
    UPDATE_FILE: (id: number) => `${environment.baseUrl}/files/edit/${id}`,
  },

  GOOGLE_AUTH: {
    GOOGLE_CALLBACK: `${environment.baseUrl}/auth/google/callback`,
  },

  OMS: {
    GET_FILTERS: (category: string) =>
      `${environment.baseUrl}/oms/filters/${category}`,
    CREATE_NEW_TASK: `${environment.baseUrl}/oms/task/rfq`,
    GET_TASKS: (category: string) =>
      `${environment.baseUrl}/oms/task/${category}`,
    UPDATE_TASK_DETAILS: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}`,
    UPDATE_CATEGORY_TASK_DETAILS: (id: number, category: string) =>
      `${environment.baseUrl}/oms/task/${category}/${id}`,
    GET_TASK_DETAILS: (id: number, category: string) =>
      `${environment.baseUrl}/oms/${category}/${id}`,
    GET_SAMPLING_TASK_DETAILS: (
      id: number,
      category: string,
      vendor_id: string | undefined,
    ) => `${environment.baseUrl}/oms/${category}/${id}/vendor/${vendor_id}`,
    ADD_TASK_COMMENT: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/comment`,
    ADD_PURCHASE_REQUEST_COMMENT: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${id}/comment`,
    UPLOAD_TASK_FILE: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/files`,
    DELETE_TASK_FILE: (id: number, asset_record_id: number) =>
      `${environment.baseUrl}/oms/task/${id}/file/${asset_record_id}`,
    ADD_TASK_PRODUCT: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/product`,
    UPDATE_TASK_POCS: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/poc`,
    GET_TASK_CATEGORY_STATUSES: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/statuses`,
    ADD_VENDOR_DETAIL: (sourcingId: number) =>
      `${environment.baseUrl}/oms/sourcing/${sourcingId}/add_vendor`,
    UPDATE_VENDOR_DETAILS: `${environment.baseUrl}/oms/update_vendor_details`,
    DELETE_VENDOR_DETAIL: (sourcingId: number) =>
      `${environment.baseUrl}/oms/sourcing/${sourcingId}/delete_vendor`,
    VENDOR_NEGOTIATION: (sourcingId: number) =>
      `${environment.baseUrl}/oms/sourcing/${sourcingId}/vendor_history`,
    ADD_SAMPLE_ADDRESS: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/sample_address`,
    UPDATE_COA: (tpm_id: number) =>
      `${environment.baseUrl}/oms/task/${tpm_id}/coa`,
    GET_PRODUCTS: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/products`,
    DELETE_TASK_PRODUCT: (tpm_id: number) =>
      `${environment.baseUrl}/oms/delete_product/${tpm_id}`,
    GET_PURCHASE_REQUESTS: (category: string) =>
      `${environment.baseUrl}/oms/purchase_requests/${category}`,
    CREATE_PURCHASE_REQUEST: `${environment.baseUrl}/oms/purchase_requests/purchase-order`,
    ADD_PURCHASE_PRODUCT: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${id}/product`,
    GET_PURCHASE_ORDER_DETAILS: (
      id: number,
      category: string,
      vendor_id?: string,
    ) =>
      `${environment.baseUrl}/oms/purchase_requests/${category}/${id}?vendor_id=${vendor_id}`,
    DELETE_PURCHASE_ORDER_FILE: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${id}/file`,
    UPLOAD_PURCHASE_ORDER_FILE: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${id}/files`,
    DELIVERY_SCHEDULE_DATA: `${environment.baseUrl}/oms/purchase_requests/products/delivery_schedules`,
    DELIVERY_COMPLETED: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/products/delivery_schedules/${id}`,
    DELETE_DELIVERY: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/products/delivery_schedules/${id}`,
    ADD_PRODUCT_DELIVERY_SCHEDULE: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${id}/delivery_schedule`,
    DELETE_TASK: (id: number) => `${environment.baseUrl}/oms/rfq/${id}`,
    UPDATE_PURCHASE_ORDER_DETAILS: (id: number, category: string) =>
      `${environment.baseUrl}/oms/purchase_requests/${category}/${id}`,
    UPDATE_PURCHASE_ORDER_PRODUCT: (id: number, prtpm_id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${id}/product/${prtpm_id}`,
    UPDATE_DELIVERY_DETAILS: (id: number, prtpm_id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${prtpm_id}/delivery_schedule/${id}`,
    GET_SKU_LIST: (id: number) => `${environment.baseUrl}/oms/skus/${id}`,
    GET_CLIENT_ADDRESSES: (client_id: number) =>
      `${environment.baseUrl}/oms/addresses/${client_id}`,
    UPDATE_SKU_LIST: (tpm_id: number) =>
      `${environment.baseUrl}/oms/sku/update/${tpm_id}}`,
    FETCH_PURCHASE_COMMENT: (po_id: number, vendor_id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${po_id}/comment/${vendor_id}`,
    DELETE_PURCHASE_REQUEST_VENDOR: (po_id: number, vendor_id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${po_id}/vendor/${vendor_id}`,
    SKU_VENDOR_DETAILS: (po_id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${po_id}/product/vendors`,
  },
  COMPANY: {
    NEW: `${environment.baseUrl}/company/new`,
    GET_POCS: (id: number) => `${environment.baseUrl}/company/${id}/pocs`,
  },
  SKU: {
    CREATE_SKU: `${environment.baseUrl}/oms/sku_internals/create`,
    GET_SKU_LIST: `${environment.baseUrl}/oms/sku_internals`,
    GET_SKU_DETAIL: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/details`,
    UPDATE_SKU_DETAILS: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/update`,
    DELETE_SKU_DETAILS: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/delete`,
    SKU_DETAILS_ADD_POC: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/add_poc`,

    GET_SKU_QUALITY_CHECKS: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/quality_checks`,
    CREATE_SKU_QUALITY_CHECK: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/quality_checks/create`,
    UPDATE_SKU_QUALITY_CHECK: (quality_check_id: number) =>
      `${environment.baseUrl}/oms/sku_internals/quality_check/${quality_check_id}/update`,
    DELETE_SKU_QUALITY_CHECK: (quality_check_id: number) =>
      `${environment.baseUrl}/oms/sku_internals/quality_check/${quality_check_id}/delete`,
    GET_SKU_INTERNAL_PRICING: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/pricing`,
    GET_HISTORY_SKU_INTERNAL_PRICING: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/pricing_history`,
    UPDATE_SKU_INTERNAL_PRICING: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/update_vendor_pricing`,
    DELETE_SKU_INTERNAL_PRICING: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/delete_vendor_pricing`,
    ADD_VENDOR_PRICING: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/add_vendor_pricing`,
    GET_SKU_ARTWORKS: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/artworks`,
    GET_SKU_ARTWORK_COMMENTS: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/artwork_comments`,

    ADD_ARTWORK: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/add_artwork`,
    ADD_SKU_ARTWORK_COMMENT: (id: number) =>
      `${environment.baseUrl}/oms/sku_internals/${id}/add_artwork_comment`,
    UPDATE_ARTWORK_STATUS: `${environment.baseUrl}/oms/sku_internals/update_artwork_status`,
  },
  PURCHASE_REQUEST: {
    GET_SKU_LIST_WITH_DETAIL: (id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${id}/client_skus_list`,
    SKU_ADD_PRODUCT: (purchase_request_id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${purchase_request_id}/product`,
    ASSIGN_VENDOR: (purchase_request_id: number) =>
      `${environment.baseUrl}/oms/purchase_requests/${purchase_request_id}/assign_vendors`,
  },
  DELIVERY_DETAILS: {
    GET_DELIVERY_DETAILS: (id: number) =>
      `${environment.baseUrl}/oms/delivery_details/${id}`,
    UPDATE_DELIVERY_DETAILS: (id: number) =>
      `${environment.baseUrl}/oms/delivery_details/${id}/update`,
    GET_DELIVERY_QUALITY_CHECK_DETAILS: (id: number) =>
      `${environment.baseUrl}/oms/delivery_details/${id}/quality_checks`,
    UPDATE_DELIVERY_QUALITY_CHECK_DETAILS: (id: number) =>
      `${environment.baseUrl}/oms/delivery_details/${id}/quality_checks/update`,
    ADD_ATTACHMENTS: (id: number) =>
      `${environment.baseUrl}/oms/delivery_details/${id}/add_attachments`,
  },
};

export default URL;
