import React, { RefObject, useEffect, useRef, useState } from "react";
import "./SkuQualityCheckCard.scss";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import OutsideAlerter from "../../utils/OutsideClickDetector";

interface SkuQualityCheckCardProps {
  index: number;
  qualityCheck: {
    id: number;
    key: string;
    unit: string | null;
    value: string | null;
    sku_internal_id: number;
  };
  handleDeleteQualityCheck: (id: number) => void;
}
const SkuQualityCheckCard: React.FC<SkuQualityCheckCardProps> = ({
  qualityCheck,
  index,
  handleDeleteQualityCheck,
}) => {
  const keyRef = useRef<HTMLParagraphElement>(null);
  const unitRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<HTMLInputElement>(null);
  const [unitEditingIndex, setUnitEditingIndex] = useState(-1);
  const [unitVal, setUnitVal] = useState<string>(qualityCheck.unit || "");
  const [standardVal, setStandardVal] = useState<string>(
    qualityCheck.value || "",
  );
  const [valueEditingIndex, setValueEditingIndex] = useState(-1);
  const [editingKey, setEditingKey] = useState(false);
  const [keyName, setKeyName] = useState(qualityCheck.key);

  useEffect(() => {
    if (editingKey && keyRef.current) {
      (keyRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(keyRef.current);
    }
  }, [editingKey]);

  const handleUnitKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (unitVal.length === 0 || unitVal === qualityCheck.unit) {
        setUnitEditingIndex(-1);
        setUnitVal(qualityCheck.unit || "");
        if (unitRef.current) {
          unitRef.current.blur();
        }
        return;
      }
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_QUALITY_CHECK(qualityCheck.id),
        {
          unit: unitVal,
        },
      );
      qualityCheck.unit = data.data.unit;
      setUnitEditingIndex(-1);
      if (unitRef.current) {
        unitRef.current.blur();
      }
    }
  };

  const handleValueKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (standardVal.length === 0 || standardVal === qualityCheck.value) {
        setValueEditingIndex(-1);
        setStandardVal(qualityCheck.value || "");
        if (valueRef.current) {
          valueRef.current.blur();
        }
        return;
      }
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_QUALITY_CHECK(qualityCheck.id),
        {
          value: standardVal,
        },
      );
      qualityCheck.value = data.data.value;
      setValueEditingIndex(-1);
      if (valueRef.current) {
        valueRef.current.blur();
      }
    }
  };

  const setCaretToEnd = (element: HTMLElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLParagraphElement>,
    ref: RefObject<HTMLParagraphElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }

    if (
      event.key === "Backspace" &&
      ref.current?.innerText === "" &&
      keyRef.current
    ) {
      (keyRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(keyRef.current);
      event.preventDefault();
    }
  };

  const handleKeyUpdate = async () => {
    if (keyRef.current) {
      if (keyRef.current.textContent === keyName) {
        setEditingKey(false);
        return;
      }
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_QUALITY_CHECK(qualityCheck.id),
        {
          key: keyRef.current.textContent,
        },
      );
      if (keyRef.current.textContent) {
        setKeyName(keyRef.current.textContent);
      }
      setEditingKey(false);
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await ApiService().client.delete(
        URLS.SKU.DELETE_SKU_QUALITY_CHECK(qualityCheck.id),
      );
      handleDeleteQualityCheck(qualityCheck.id);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div
      key={index}
      className="quality-check-card-main-container quality-check-grid"
    >
      <p className="index flex-row align-items-center p-12">
        {(index.toString().length === 2
          ? "0"
          : index.toString().length > 2
          ? ""
          : "00") +
          (index + 1)}
      </p>
      <div className="flex-row align-items-center">
        <div className="key-main-container flex-row align-items-center justify-content-space-between cursor-pointer p-12 flex-1 gap-8">
          <p
            ref={keyRef}
            contentEditable={editingKey}
            suppressContentEditableWarning={true}
            className="parameter cursor-pointer flex-1 bold"
            onKeyDown={(e) => handleKeyDown(e, keyRef)}
          >
            {keyName}
          </p>
          {!editingKey ? (
            <div className="edit-del-btn flex-row gap-8">
              <div
                onClick={() => handleDelete()}
                className="delete-container flex-row align-items-center justify-content-center p-4 cursor-pointer"
              >
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308111001-trash-2.svg"
                  alt="delete-icon"
                />
              </div>
              <div
                onClick={() => setEditingKey(true)}
                className="edit-container flex-row align-items-center justify-content-center p-4 cursor-pointer"
              >
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506100306-pen-square+%281%29.svg"
                  alt="edit-icon"
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                handleKeyUpdate();
              }}
              className="save xetgo-font-tag bold flex-row align-items-end cursor-pointer"
            >
              Save
            </div>
          )}
        </div>
        <div className="p-12 unit-container">
          <OutsideAlerter action={() => setUnitEditingIndex(-1)}>
            <input
              ref={unitRef}
              key={qualityCheck.id}
              onClick={() => setUnitEditingIndex(qualityCheck.id)}
              className={`quality-check-input full-width unit px-16 py-8 border-box xetgo-font-tag ${
                unitEditingIndex === qualityCheck.id ? "unit-editing" : ""
              }`}
              onChange={(e) => setUnitVal(e.target.value.replace(/\s+/g, " "))}
              onKeyDown={(e) => handleUnitKeyDown(e)}
              value={unitVal}
            />
          </OutsideAlerter>
        </div>
      </div>

      <div className="p-12">
        <OutsideAlerter action={() => setValueEditingIndex(-1)}>
          <input
            ref={valueRef}
            key={qualityCheck.id}
            onClick={() => setValueEditingIndex(qualityCheck.id)}
            className={`quality-check-input property-value px-16 full-width py-8 border-box xetgo-font-tag ${
              valueEditingIndex === qualityCheck.id ? "editing-value" : ""
            }`}
            onChange={(e) =>
              setStandardVal(e.target.value.replace(/\s+/g, " "))
            }
            onKeyDown={(e) => handleValueKeyDown(e)}
            value={standardVal}
          />
        </OutsideAlerter>
      </div>
    </div>
  );
};

export default SkuQualityCheckCard;
