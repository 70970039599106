import React, { useState } from "react";
import "./EditAddressAddPopup.scss";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { AddressSchema } from "../../models/SkuListSchema";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

interface UpdateSkuInternalPayload {
  location: AddressSchema;
  new_Addresses?: AddressSchema[];
}

interface AddPocPopupProps {
  handleClose: () => void;
  clientId: number;
  skuId: number;
  update?: boolean;
  addressDetail: AddressSchema;
  handleAddressSave: (addressData: any) => void;
}

const EditAddressAddPopup: React.FC<AddPocPopupProps> = ({
  handleClose,
  clientId,
  update,
  addressDetail,
  handleAddressSave,
  skuId,
}) => {
  const [allAddresses, setAllAddresses] = useState<AddressSchema[]>([
    {
      id: addressDetail.id,
      address_id: addressDetail.address_id,
      sku_internal_price_id: addressDetail.sku_internal_price_id,
      address_line: addressDetail.address_line,
      pincode: addressDetail.pincode,
      country: addressDetail.country,
      city: addressDetail.city,
      state: addressDetail.state,
      lead_time: addressDetail.lead_time,
      quantity: addressDetail.quantity,
      selling_price: addressDetail.selling_price,
    },
  ]);
  const handleAddressChange = (
    index: number,
    field: keyof AddressSchema,
    value: string | number,
    field_type: string,
  ) => {
    const updatedAddresses: AddressSchema[] = [...allAddresses];

    switch (field) {
      case "pincode":
      case "lead_time":
      case "quantity":
      case "selling_price":
        updatedAddresses[index][field] = value as number;
        break;
      case "country":
      case "address_line":
      case "city":
      case "state":
        updatedAddresses[index][field] = value as string;
        break;
      default:
        break;
    }

    setAllAddresses(updatedAddresses);
  };

  const handleAddAddress = () => {
    setAllAddresses([
      ...allAddresses,
      {
        id: -1,
        address_id: -1,
        sku_internal_price_id: -1,
        address_line: "",
        pincode: 0,
        country: "",
        city: "",
        state: "",
        lead_time: 0,
        quantity: 0,
        selling_price: 0,
      },
    ]);
  };

  const handleSaveOrUpdate = async () => {
    try {
      const payload: UpdateSkuInternalPayload = {
        location: allAddresses[0],
      };

      if (allAddresses.length > 1) {
        const newLocations = allAddresses.slice(1);
        for (const address of newLocations) {
          const { city, state, country, pincode } = address;

          if (!city || !state || !country || !pincode) {
            toast.error(
              "Please fill in all required fields for the additional addresses.",
              toastOptions,
            );
            return;
          }
        }

        payload.new_Addresses = newLocations;
      }

      const { data } = await ApiService().client.put(
        URL.SKU.UPDATE_SKU_DETAILS(skuId),
        payload,
      );

      handleAddressSave(data.data);
      handleClose();
    } catch (error) {
      console.error("Error updating SKU details:", error);
    }
  };
  const handleReset = () => {
    setAllAddresses([
      {
        id: addressDetail.id,
        address_id: addressDetail.address_id,
        sku_internal_price_id: 0,
        address_line: addressDetail.address_line,
        pincode: addressDetail.pincode,
        country: addressDetail.country,
        city: addressDetail.city,
        state: addressDetail.state,
        lead_time: addressDetail.lead_time,
        quantity: addressDetail.quantity,
        selling_price: addressDetail.selling_price,
      },
    ]);
  };

  return (
    <div className="add-address-backdrop-main-container flex-row align-items-center justify-content-center">
      <OutsideAlerter
        className="address-add-main-container"
        action={handleClose}
      >
        <div>
          <div className="add-address-upper-bar flex-row align-items-center justify-content-space-between py-12 px-16">
            <p style={{ color: "black" }} className="xetgo-font-button bold">
              Address
            </p>
            <img
              onClick={handleClose}
              className="cursor-pointer"
              height={8}
              width={8}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
              alt="close-icon"
            />
          </div>

          <div className="all-new-address-container">
            {allAddresses.map((address, index) => (
              <div key={index} className="flex-column gap-12 py-12 px-16">
                {index > 0 && (
                  <p style={{ color: "black" }} className="bold py-4">
                    New Address {index}
                  </p>
                )}
                <div className="flex-row gap-8">
                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">Address Line</p>
                    <input
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter address..."
                      value={address.address_line}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "address_line",
                          e.target.value,
                          "STRING",
                        )
                      }
                    />
                  </div>
                </div>

                <div className="flex-row gap-8">
                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">
                      Country <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter Country"
                      value={address.country}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "country",
                          e.target.value,
                          "STRING",
                        )
                      }
                    />
                  </div>

                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">
                      Pincode <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="number"
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter Pincode"
                      value={address.pincode}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "pincode",
                          e.target.valueAsNumber,
                          "NUMBER",
                        )
                      }
                    />
                  </div>
                </div>

                <div className="flex-row gap-8">
                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">
                      City <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter City"
                      value={address.city}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "city",
                          e.target.value,
                          "STRING",
                        )
                      }
                    />
                  </div>

                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">
                      State <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter State"
                      value={address.state}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "state",
                          e.target.value,
                          "STRING",
                        )
                      }
                    />
                  </div>
                </div>

                <div className="flex-row gap-8">
                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">Lead Time</p>
                    <input
                      type="number"
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter Lead Time"
                      value={address.lead_time}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "lead_time",
                          e.target.valueAsNumber,
                          "NUMBER",
                        )
                      }
                    />
                  </div>

                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">MOQ</p>
                    <input
                      type="number"
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter Minimum Order Quantity"
                      value={address.quantity}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "quantity",
                          e.target.valueAsNumber,
                          "NUMBER",
                        )
                      }
                    />
                  </div>
                </div>

                <div className="flex-row gap-8">
                  <div className="flex-column gap-8 flex-1">
                    <p className="xetgo-font-tag bold">Price</p>
                    <input
                      type="number"
                      className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                      placeholder="Enter Price"
                      value={address.selling_price}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "selling_price",
                          e.target.valueAsNumber,
                          "NUMBER",
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`add-address-modal-btns-container justify-content-space-between border-box flex-row gap-12 new-form-btn-container p-16 ${
              update && "action-btns-end"
            }`}
          >
            {!update && (
              <div
                onClick={handleAddAddress}
                className="address-add-btn flex-row align-items-center cursor-pointer px-12 py-8 gap-10 xetgo-font-tag"
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                  alt="add-icon"
                />
                <p>Add Address</p>
              </div>
            )}
            <div className="flex-row gap-12 align-items-center">
              {!update ? (
                <div
                  onClick={handleReset}
                  className="cancel-btn xetgo-font-tag"
                >
                  Reset
                </div>
              ) : (
                <div
                  onClick={handleClose}
                  className="cancel-btn xetgo-font-tag"
                >
                  Cancle
                </div>
              )}

              <div
                onClick={handleSaveOrUpdate}
                className="add-btn xetgo-font-tag"
              >
                {update ? "Update" : "Save"}
              </div>
            </div>
          </div>
        </div>
      </OutsideAlerter>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default EditAddressAddPopup;
