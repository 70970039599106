import React, { useEffect, useState } from "react";
import { IDeliveryDetail } from "../../models/DeliveryDetail";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "./DeliveryDetailTabContent.scss";

interface IDeliveryDetailTabContent {
  purchased_product_delivery_schedule_id: number;
  deliveryDetails: IDeliveryDetail;
}

const DeliveryDetailTabContent: React.FC<IDeliveryDetailTabContent> = ({
  purchased_product_delivery_schedule_id,
  deliveryDetails,
}) => {
  const [isEditingQuantity, setIsEditingQuantity] = useState<boolean>(false);
  const [isEditingTrackLink, setIsEditingTrackLink] = useState(false);
  const [isEditingExpense, setIsEditingExpense] = useState(false);
  const [isEditingTransporter, setIsEditingTransporter] = useState(false);
  const [isEditingVendorLocation, setIsEditingVendorLocation] = useState(false);
  const [isEditingDriverName, setIsEditingDriverName] = useState(false);
  const [isEditingDriverNumber, setIsEditingDriverNumber] = useState(false);

  const [quantity, setQuantity] = useState<string>(
    deliveryDetails.quantity?.toString() || "0",
  );
  const [deliveryOpen, setDeliveryOpen] = useState<string>("");
  const [expectedClientDeliveryDate, setExpectedClientDeliveryDate] =
    useState<string>(deliveryDetails.expected_client_delivery_date || "");
  const [expectedVendorDeliveryDate, setExpectedVendorDeliveryDate] =
    useState<string>(deliveryDetails.expected_vendor_delivery_date || "");
  const [actualClientDeliveryDate, setActualClientDeliveryDate] =
    useState<string>(deliveryDetails.actual_client_delivery_date || "");
  const [actualVendorDeliveryDate, setActualVendorDeliveryDate] =
    useState<string>(deliveryDetails.actual_vendor_delivery_date || "");
  const [expectedDropDate, setExpectedDropDate] = useState<string>(
    deliveryDetails.dispatch_details.expected_drop_date || "",
  );
  const [trackingLink, setTrackingLink] = useState<string>(
    deliveryDetails.dispatch_details.track_link || "",
  );
  const [expense, setExpense] = useState<number>(
    deliveryDetails.dispatch_details.expense || 0,
  );
  const [transporter, setTransporter] = useState(
    deliveryDetails.dispatch_details.logistic_provider || "",
  );
  const [vendorLocation, setVendorLocation] = useState(
    deliveryDetails.dispatch_details.pickup_point || "",
  );
  const [driverName, setDriverName] = useState(
    deliveryDetails.dispatch_details.driver_name || "",
  );
  const [driverNumber, setDriverNumber] = useState(
    deliveryDetails.dispatch_details.driver_phone || "",
  );

  const updateDetails = async (key: string, value: string) => {
    await ApiService().client.put(
      URL.DELIVERY_DETAILS.UPDATE_DELIVERY_DETAILS(
        purchased_product_delivery_schedule_id,
      ),
      {
        [key]: value,
      },
    );
  };

  const onChangeHandler = (key: string, value: string) => {
    switch (key) {
      case "quantity":
        setQuantity(value);
        break;
      case "expected_delivery_date":
        setExpectedClientDeliveryDate(value);
        break;
      case "expected_vendor_delivery_date":
        setExpectedVendorDeliveryDate(value);
        break;
      case "actual_delivery_date":
        setActualClientDeliveryDate(value);
        break;
      case "actual_vendor_delivery_date":
        setActualVendorDeliveryDate(value);
        break;
      case "expected_drop_date":
        setExpectedDropDate(value);
        break;
      case "track_link":
        setExpectedDropDate(value);
        break;
      case "expense":
        setExpense(Number(value));
        break;
      case "transporter":
        setTransporter(value);
        break;
      case "vendor_location":
        setVendorLocation(value);
        break;
      case "driver_name":
        setDriverName(value);
        break;
      case "driver_number":
        setDriverNumber(value);
        break;
    }

    updateDetails(key, value);
  };

  const toggleDeliveryDateSelector = (
    key: string,
    type?: "expected" | "actual" | "new",
  ) => {
    if (deliveryOpen === `${type}-${key}`) {
      setDeliveryOpen("");
    } else {
      setDeliveryOpen(`${type}-${key}`);
    }
  };

  return (
    <div className="delivery-detail-tab-content-container flex-column gap-12">
      <div className="details-box p-12">
        <div className="detail-input half-width">
          <span className="detail-input-label">SKU Name</span>
          <span className="detail-input-value flex-row align-items-center gap-12">
            {deliveryDetails.sku.name}
          </span>
        </div>
        <div className="detail-input half-width">
          <span className="detail-input-label"> Enter Quantity </span>
          <span
            className="detail-input-value"
            onClick={() => setIsEditingQuantity(true)}
          >
            {isEditingQuantity ? (
              <input
                type="text"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                onBlur={() => {
                  setIsEditingQuantity(false);
                  onChangeHandler("quantity", quantity);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingQuantity(false);
                    onChangeHandler("quantity", quantity);
                  }
                }}
                autoFocus
              />
            ) : (
              `${quantity} ${deliveryDetails.sku.unit}`
            )}
          </span>
        </div>
        <div className="detail-input full-width">
          <span className="detail-input-label">SKU Description</span>
          <span className="detail-input-value">
            {deliveryDetails.sku.description}
          </span>
        </div>
        <div className="detail-input full-width">
          <span className="detail-input-label"> Client Name </span>
          <span className="detail-input-value">
            {deliveryDetails.client_name}
          </span>
        </div>
        <div className="detail-input full-width">
          <span className="detail-input-label"> Client Location </span>
          <span className="detail-input-value flex-row align-items-center gap-12">
            {deliveryDetails.client_location.address_line},{" "}
            {deliveryDetails.client_location.city},{" "}
            {deliveryDetails.client_location.state},{" "}
            {deliveryDetails.client_location.pincode}
            <img src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241004110343-location-purple.svg" />
          </span>
        </div>
        <div className="detail-input half-width">
          <span className="detail-input-label">
            Expected Client Delivery Date
          </span>

          <div
            style={{ color: "#484848" }}
            className="delivery-date-wrapper flex-row align-items-center gap-8"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(expectedClientDeliveryDate)}
                format={"DD-MM-YYYY"}
                views={["year", "month", "day"]}
                onChange={(event) => {
                  onChangeHandler(
                    "expected_delivery_date",
                    event?.toDate().toISOString() || "",
                  );
                  setDeliveryOpen("");
                }}
                open={deliveryOpen === "expected-client-delivery-date"}
                className="delivery-date-selector xetgo-font-tag-bold"
              />
            </LocalizationProvider>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
              onClick={() =>
                toggleDeliveryDateSelector("client-delivery-date", "expected")
              }
              className="calendar-icon cursor-pointer"
              height={16}
              width={16}
              alt=""
            />
          </div>
        </div>
        <div className="detail-input half-width">
          <span className="detail-input-label">
            {" "}
            Expected Vendor Delivery Date
          </span>

          <div
            style={{ color: "#484848" }}
            className="delivery-date-wrapper flex-row align-items-center gap-8"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(expectedVendorDeliveryDate)}
                format={"DD-MM-YYYY"}
                views={["year", "month", "day"]}
                onChange={(event) => {
                  onChangeHandler(
                    "expected_vendor_delivery_date",
                    event?.toDate().toISOString() || "",
                  );
                  setDeliveryOpen("");
                }}
                open={deliveryOpen === "expected-vendor-delivery-date"}
                className="delivery-date-selector xetgo-font-tag-bold"
              />
            </LocalizationProvider>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
              onClick={() =>
                toggleDeliveryDateSelector("vendor-delivery-date", "expected")
              }
              className="calendar-icon cursor-pointer"
              height={16}
              width={16}
              alt=""
            />
          </div>
        </div>
        <div className="detail-input half-width">
          <span className="detail-input-label">
            {" "}
            Actual Client Delivery Date{" "}
          </span>

          <div
            style={{ color: "#484848" }}
            className="delivery-date-wrapper flex-row align-items-center gap-8"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(actualClientDeliveryDate)}
                format={"DD-MM-YYYY"}
                views={["year", "month", "day"]}
                onChange={(event) => {
                  onChangeHandler(
                    "actual_delivery_date",
                    event?.toDate().toISOString() || "",
                  );
                  setDeliveryOpen("");
                }}
                open={deliveryOpen === "actual-client-delivery-date"}
                className="delivery-date-selector xetgo-font-tag-bold"
              />
            </LocalizationProvider>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
              onClick={() =>
                toggleDeliveryDateSelector("client-delivery-date", "actual")
              }
              className="calendar-icon cursor-pointer"
              height={16}
              width={16}
              alt=""
            />
          </div>
        </div>
        <div className="detail-input half-width">
          <span className="detail-input-label">
            {" "}
            Actual Vendor Delivery Date{" "}
          </span>

          <div
            style={{ color: "#484848" }}
            className="delivery-date-wrapper flex-row align-items-center gap-8"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(actualVendorDeliveryDate)}
                format={"DD-MM-YYYY"}
                views={["year", "month", "day"]}
                onChange={(event) => {
                  onChangeHandler(
                    "actual_vendor_delivery_date",
                    event?.toDate().toISOString() || "",
                  );
                  setDeliveryOpen("");
                }}
                open={deliveryOpen === "actual-vendor-delivery-date"}
                className="delivery-date-selector xetgo-font-tag-bold"
              />
            </LocalizationProvider>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
              onClick={() =>
                toggleDeliveryDateSelector("vendor-delivery-date", "actual")
              }
              className="calendar-icon cursor-pointer"
              height={16}
              width={16}
              alt=""
            />
          </div>
        </div>
      </div>

      {/* DISPATCH DETAILS */}

      <div className="details-box p-12">
        <span
          className="full-width xetgo-font-tag-bold"
          style={{ color: "#484848" }}
        >
          {" "}
          Dispatch Details{" "}
        </span>

        <div className="detail-input full-width">
          <span className="detail-input-label"> Logistics Provider </span>
          <span
            className="detail-input-value"
            onClick={() => setIsEditingTransporter(true)}
          >
            {isEditingTransporter ? (
              <input
                type="text"
                value={transporter}
                onChange={(e) => setTransporter(e.target.value)}
                onBlur={() => {
                  setIsEditingTransporter(false);
                  onChangeHandler("transporter", transporter);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingTransporter(false);
                    onChangeHandler("transporter", transporter);
                  }
                }}
                autoFocus
              />
            ) : (
              transporter || "Add Logistics Provider"
            )}
          </span>
        </div>

        <div className="detail-input full-width">
          <span className="detail-input-label"> Pickup Point </span>
          <span
            className="detail-input-value"
            onClick={() => setIsEditingVendorLocation(true)}
          >
            {isEditingVendorLocation ? (
              <input
                type="text"
                value={vendorLocation}
                onChange={(e) => setVendorLocation(e.target.value)}
                onBlur={() => {
                  setIsEditingVendorLocation(false);
                  onChangeHandler("vendor_location", vendorLocation);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingVendorLocation(false);
                    onChangeHandler("vendor_location", vendorLocation);
                  }
                }}
                autoFocus
              />
            ) : (
              vendorLocation || "Add a pickup point"
            )}
          </span>
        </div>

        <div className="detail-input half-width">
          <span className="detail-input-label"> Expense </span>
          <span className="detail-input-value flex-row align-items-center gap-12">
            {isEditingExpense ? (
              <input
                type="text"
                value={expense}
                onChange={(e) => setExpense(Number(e.target.value))}
                onBlur={() => {
                  setIsEditingExpense(false);
                  onChangeHandler("expense", expense.toString());
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingExpense(false);
                    onChangeHandler("expense", expense.toString());
                  }
                }}
                autoFocus
              />
            ) : (
              <span onClick={() => setIsEditingExpense(true)}>{expense}</span>
            )}
            ₹
          </span>
        </div>

        <div className="detail-input half-width">
          <span className="detail-input-label"> Expected Drop Date </span>
          <div
            style={{ color: "#484848" }}
            className="delivery-date-wrapper flex-row align-items-center gap-8"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(expectedDropDate)}
                format={"DD-MM-YYYY"}
                views={["year", "month", "day"]}
                onChange={(event) => {
                  onChangeHandler(
                    "expected_drop_date",
                    event?.toDate().toISOString() || "",
                  );
                  setDeliveryOpen("");
                }}
                open={deliveryOpen === "expected-drop-date"}
                className="delivery-date-selector xetgo-font-tag-bold"
              />
            </LocalizationProvider>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
              onClick={() =>
                toggleDeliveryDateSelector("drop-date", "expected")
              }
              className="calendar-icon cursor-pointer"
              height={16}
              width={16}
              alt=""
            />
          </div>
        </div>

        <div className="detail-input half-width">
          <span className="detail-input-label"> Contact Name </span>
          <span className="detail-input-value flex-row align-items-center gap-12">
            {isEditingDriverName ? (
              <input
                type="text"
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
                onBlur={() => {
                  setIsEditingDriverName(false);
                  onChangeHandler("driver_name", driverName);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingDriverName(false);
                    onChangeHandler("driver_name", driverName);
                  }
                }}
                autoFocus
              />
            ) : (
              <span onClick={() => setIsEditingDriverName(true)}>
                {driverName || "Add driver name"}
              </span>
            )}
          </span>
        </div>

        <div className="detail-input half-width">
          <span className="detail-input-label"> Contact Number </span>
          <span className="detail-input-value flex-row align-items-center gap-12">
            {isEditingDriverNumber ? (
              <input
                type="text"
                value={driverNumber}
                onChange={(e) => setDriverNumber(e.target.value)}
                onBlur={() => {
                  setIsEditingDriverNumber(false);
                  onChangeHandler("driver_number", driverNumber);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingDriverNumber(false);
                    onChangeHandler("driver_number", driverNumber);
                  }
                }}
                autoFocus
              />
            ) : (
              <span onClick={() => setIsEditingDriverNumber(true)}>
                {driverNumber || "Add driver number"}
              </span>
            )}
          </span>
        </div>

        <div className="detail-input full-width">
          <span className="detail-input-label"> Tracking Link </span>
          <span className="detail-input-value flex-row align-items-center gap-12">
            {isEditingTrackLink ? (
              <input
                type="text"
                value={trackingLink}
                onChange={(e) => setTrackingLink(e.target.value)}
                onBlur={() => {
                  setIsEditingTrackLink(false);
                  onChangeHandler("track_link", trackingLink);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingTrackLink(false);
                    onChangeHandler("track_link", trackingLink);
                  }
                }}
                autoFocus
              />
            ) : (
              <span onClick={() => setIsEditingTrackLink(true)}>
                {trackingLink || "Add tracking link"}
              </span>
            )}
            <img src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241004110343-location-purple.svg" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetailTabContent;
