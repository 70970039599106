import React, { useEffect, useState } from "react";
import "./SkuPricingTab.scss";
import {
  SkuDetailSchema,
  SkuVendorPrice,
  SkuVendorPriceHistory,
} from "../../models/SkuListSchema";
import ApiService from "../../services/apiService";
import SkuPricingTabCard from "../SkuPricingTabCard/SkuPricingTabCard";
import URL from "../../urls/Urls";
import { useDispatch } from "react-redux";
import { fetchFilter } from "../../redux/oms/omsActions";

interface SkuPricingProps {
  sku: SkuDetailSchema;
  refresh: boolean;
}

const SkuPricingTab: React.FC<SkuPricingProps> = ({ sku, refresh }) => {
  const [pricingList, setPricingList] = useState<SkuVendorPriceHistory[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilter("EQS"));
  }, []);

  useEffect(() => {
    getSkuInternalPricing();
  }, [refresh]);

  const getSkuInternalPricing = async () => {
    const { data } = await ApiService().client.get(
      URL.SKU.GET_SKU_INTERNAL_PRICING(sku.id),
    );
    setPricingList(data.data.pricing_list);
  };

  const handleDeletion = (deletedId: number) => {
    setPricingList((prevList) =>
      prevList.filter((item) => item.id !== deletedId),
    );
  };
  const handlePricingListUpdate = (
    pricingId: number,
    dataToUpdate: SkuVendorPriceHistory,
  ) => {
    setPricingList((prevList) =>
      prevList.map((item) =>
        item.id === pricingId ? { ...item, ...dataToUpdate } : item,
      ),
    );
  };
  return (
    <div className="sku-pricing-container flex-column">
      <div className="sku-pricing-header sku-pricing-main-grid">
        <div className="sku-left-grid ">
          <div className="flex-row gap-8 align-items-center p-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
              alt="warehouse"
              width={12}
              height={12}
            />
            <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
              Warehouse
            </p>
          </div>
          <div className="flex-row gap-8 align-items-center p-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
              alt="user-icon"
              width={12}
              height={12}
            />
            <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
              Vendor
            </p>
          </div>
        </div>

        <div className="sku-right-grid">
          <div className="flex-row gap-8 align-items-center p-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240716182234-boxes.svg"
              alt="moq"
              width={12}
              height={12}
            />
            <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
              MOQ
            </p>
          </div>
          <div className="flex-row gap-8 align-items-center p-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240709111945-%E2%82%B9.svg"
              alt="price"
              height={12}
            />
            <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
              Price
            </p>
          </div>
          <div className="flex-row gap-8 align-items-center p-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-table-properties.svg"
              alt="warehouse"
              width={12}
              height={12}
            />
            <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
              Lead Time
            </p>
          </div>
          <div className="flex-row gap-8 align-items-center p-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
              alt="warehouse"
              width={12}
              height={12}
            />
            <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
              Start Time
            </p>
          </div>
          <div className="flex-row gap-8 align-items-center p-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240923082425-history.svg"
              alt="source"
              width={12}
              height={12}
            />
            <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
              Actions
            </p>
          </div>
        </div>
      </div>

      {pricingList.length > 0 && (
        <div className="all-sku-pricing-main-container flex-1">
          {pricingList.map((priceDetail, index) => (
            <SkuPricingTabCard
              key={priceDetail.id}
              priceEntry={priceDetail}
              handleDeletion={handleDeletion}
              handlePricingListUpdate={handlePricingListUpdate}
            />
          ))}
        </div>
      )}

      {pricingList.length === 0 && (
        <div className="empty-state-qc-card flex-column gap-12 align-items-center justify-content-center xetgo-font-tag bold flex-1">
          <img
            height={21}
            width={20}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808061403-Document+add.svg"
            alt="add-file-icon"
          />
          <p>Add Vendor Prices</p>
        </div>
      )}
    </div>
  );
};

export default SkuPricingTab;
