import React, { useState } from "react";
import "./ArtworkDropdown.scss";
import { STATUS_PROPERTY_MAP } from "../SkuArtworkTab/SkuArtworkTab.data";
import OutsideAlerter from "../../utils/OutsideClickDetector";

interface ArtworkDropdownProps {
  currentStatus: string;
  artworkId: number;
  handleStatusUpdate: (id: number, newStatus: string) => void;
  openUpwards: boolean;
}

const ArtworkDropdown: React.FC<ArtworkDropdownProps> = ({
  currentStatus,
  artworkId,
  handleStatusUpdate,
  openUpwards,
}) => {
  const STATUS = [
    {
      id: 11,
      name: "Approved",
    },
    {
      id: 12,
      name: "Rework",
    },
    {
      id: 13,
      name: "Review",
    },
    {
      id: 14,
      name: "Rejected",
    },
  ];

  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);

  const handleStatusChange = (status: string) => {
    handleStatusUpdate(artworkId, status);
    setOpenStatusDropdown(false);
  };

  return (
    <div className="artwork-dropdown-main-container">
      <OutsideAlerter action={() => setOpenStatusDropdown(false)}>
        <div
          onClick={() => setOpenStatusDropdown(!openStatusDropdown)}
          style={{ background: STATUS_PROPERTY_MAP[currentStatus].background }}
          className="sku-artwork-dropdown-container flex-row gap-4 align-items-center justify-content-center px-7 py-4 border-box cursor-pointer"
        >
          <span
            style={{ color: STATUS_PROPERTY_MAP[currentStatus].color }}
            className="capitalised-status xetgo-font-tag"
          >
            {currentStatus.toLowerCase()}
          </span>
          <svg
            className="cursor-pointer"
            width={16}
            height={16}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {!openUpwards ? (
              <path
                d="M12 8l6 6H6l6-6z" // Upward arrow path
                fill={STATUS_PROPERTY_MAP[currentStatus].color}
              />
            ) : (
              <path
                d="M12 16l-6-6h12l-6 6z" // Downward arrow path
                fill={STATUS_PROPERTY_MAP[currentStatus].color}
              />
            )}
          </svg>
          {openStatusDropdown && (
            <div
              className={`status-dropdown-container flex-column -align-items-center justify-content-center px-2 py-8 border-box gap-8 ${
                !openUpwards && "upward-opening"
              }`}
            >
              {STATUS.filter(
                (stats, statIndex) =>
                  stats.name.toLowerCase() !== currentStatus.toLowerCase(),
              ).map((status, index) => (
                <p
                  onClick={() => handleStatusChange(status.name)}
                  key={index}
                  style={{
                    color: STATUS_PROPERTY_MAP[status.name.toUpperCase()].color,
                  }}
                  className={`xetgo-font-tag status-container px-10 py-6 status-tag flex-row align-items-center gap-4 cursor-pointer ${status.name}-hover`}
                >
                  {status.name}
                </p>
              ))}
            </div>
          )}
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default ArtworkDropdown;
