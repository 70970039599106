import React, { useEffect, useMemo, useState } from 'react';
import './Options.scss';
import Filter from './Filter/Filter';
import { FilterTypes, getFilterList } from './Filter/Filter.data';
import { useDispatch } from 'react-redux';
import {
  createPurchaseRequest,
  createTask,
  fetchFilter,
  fetchPurchaseRequests,
  fetchTasks,
  setFilter,
  setSearch,
} from '../../../redux/oms/omsActions';
import XetDrawer from '../../shared/xet-drawer/XetDrawer';
import { FilterOptionSchema } from '../../../redux/oms/omsTypes';
import { SearchTypes } from './Filter/SearchTypes.data';
import NewRFQForm from '../NewRFQForm/NewRFQForm';
import { useSelector } from 'react-redux';
import {
  selectAllFilterOptions,
  selectFiltersApplied,
  selectSearchFilter,
} from '../../../redux/oms/omsSelectors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PurchaseRequestForm from '../PurchaseRequestForm/PurchaseRequestForm';
import { debounce } from 'lodash';
import { userDetails } from '../../../redux/user/userSelectors';
import { IOmsStatusTab } from '../../../models/Oms';

type IOptions = {
  showStatusTabs: boolean;
  selectedTab?: IOmsStatusTab;
};

const Options: React.FC<IOptions> = ({ showStatusTabs, selectedTab }) => {
  const dispatch = useDispatch();
  const user = useSelector(userDetails);
  const allFilterOptions = useSelector(selectAllFilterOptions);
  const selectedFilterOptions = useSelector(selectFiltersApplied);
  const [openCreateTaskForm, setOpenCreateTaskForm] = useState(false);
  const [searchText, setSearchText] = useState(useSelector(selectSearchFilter));
  const { category } = useParams<{ category: string }>();
  const location = useLocation();
  const [filterList, setFilterList] = useState<FilterTypes[]>([]);
  const [tabSelected, setTabSelected] = useState<IOmsStatusTab | undefined>(
    selectedTab
  );
  const navigate = useNavigate();

  useEffect(() => {
    const source = location.pathname.indexOf('eqs') > -1 ? 'EQS' : 'XETRACK';
    dispatch(fetchFilter(source));

    if (user) {
      setFilterList(getFilterList(user.role));
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectedTab) {
      setTabSelected(selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (showStatusTabs && tabSelected) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('status', tabSelected);

      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
      if (category) {
        if (location.pathname.indexOf('eqs') > -1) {
          dispatch(fetchTasks(category.toUpperCase(), tabSelected));
        } else {
          dispatch(fetchPurchaseRequests(category.toUpperCase(), tabSelected));
        }
      }
    }
  }, [category, tabSelected]);

  const handleCreateTaskForm = () => {
    setOpenCreateTaskForm(true);
  };

  const handleCreateTaskSubmission = async (task: FormData) => {
    if (category === 'rfq') {
      dispatch(createTask(task));
    } else {
      dispatch(createPurchaseRequest(task));
    }
    setOpenCreateTaskForm(false);
  };

  const handleFilterSelection = (
    val: FilterOptionSchema[],
    option: FilterTypes | SearchTypes
  ) => {
    const o = option as FilterTypes;
    dispatch(setFilter({ options: val, category: o.key }));
  };

  const handleClose = () => {
    setOpenCreateTaskForm(false);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    debouncedSearch(event.target.value);
  };

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      dispatch(setSearch(searchText));
    }, 500);
  }, []);

  return (
    <>
      <XetDrawer
        open={openCreateTaskForm}
        handleClose={handleClose}
        position="right"
      >
        <>
          {category === 'rfq' ? (
            <NewRFQForm
              onSubmit={handleCreateTaskSubmission}
              onClose={handleClose}
            />
          ) : (
            <PurchaseRequestForm
              onSubmit={handleCreateTaskSubmission}
              onClose={handleClose}
            />
          )}
        </>
      </XetDrawer>

      <div className="oms-header-container full-width flex-row gap-16 justify-content-space-between align-items-center flex-wrap">
        <div className="oms-header-left flex-row gap-8 align-items-center flex-1">
          {(category === 'rfq' || category === 'purchase-order') && (
            <div
              className="new-task flex-row align-items-center cursor-pointer"
              onClick={handleCreateTaskForm}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231007090447-white_add.svg"
                alt="new-task"
                width={15}
                height={16}
              />
              <p className="xetgo-font-tag m-0 title">Create Task</p>
            </div>
          )}
          <div className="search-task position-relative">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240109132059-search.svg"
              width={16}
              height={16}
              alt="search"
              className="search-icon"
            />
            <input
              className="search-input-filter xetgo-font-tag "
              value={searchText}
              placeholder="Search"
              onChange={onSearchChange}
            />
          </div>
        </div>

        <div className="oms-header-right flex-row gap-8 align-items-center">
          {showStatusTabs && (
            <div className="status-toggle flex-row align-items-center p-2">
              <div
                className={`xetgo-font-mini bold cursor-pointer px-8 py-4 ${
                  tabSelected === 'ongoing' ? 'active' : ''
                }`}
                onClick={() => setTabSelected('ongoing')}
              >
                Ongoing
              </div>

              <div
                className={`xetgo-font-mini bold cursor-pointer px-8 py-4 ${
                  tabSelected === 'completed' ? 'active' : ''
                }`}
                onClick={() => setTabSelected('completed')}
              >
                Completed
              </div>
            </div>
          )}

          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231010064729-grey_filter.svg"
            alt="filter"
            width={19}
            height={19}
            style={{ marginRight: '6px' }}
          />
          {filterList.map((option: FilterTypes) => {
            return (
              <Filter
                option={option}
                key={option.key}
                multipleSelection={true}
                onSelection={handleFilterSelection}
                optionList={allFilterOptions[option.key]}
                preSelected={selectedFilterOptions[option.key]}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Options;
