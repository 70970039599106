import React, { useEffect, useMemo, useState } from 'react';
import './Sku.scss';
import SideNav from '../shared/sidenav/SideNav';
import InfiniteScroll from 'react-infinite-scroll-component';
import ApiService from '../../services/apiService';
import URL from '../../urls/Urls';
import Filter from '../oms/Options/Filter/Filter';
import { FilterOptionSchema } from '../../redux/oms/omsTypes';
import { useSelector } from 'react-redux';
import { selectFilterOptions } from '../../redux/oms/omsSelectors';
import { filters } from '../oms/Options/Filter/Filter.data';

import { ToastContainer, toast } from 'react-toastify';
import { toastOptions } from '../../utils/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import SkuForm from '../SkuForm/SkuForm';
import { SkuSchema } from '../../models/SkuListSchema';
import { useDispatch } from 'react-redux';
import { fetchFilter } from '../../redux/oms/omsActions';

const Sku: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const clients = useSelector((state) =>
    selectFilterOptions(state, filters[1].key)
  );
  const [searchedText, setSearchedText] = useState('');
  const [skuList, setSkuList] = useState<SkuSchema[]>([]);
  const [showCreateSku, setShowCreateSku] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedClient, setSelectedClient] = useState<FilterOptionSchema[]>(
    []
  );
  const [hasMore, setHasMore] = useState(true);
  const perPage = 20;

  useEffect(() => {
    dispatch(fetchFilter('eqs'));
  }, []);

  useEffect(() => {
    getSkuList(searchedText, 1);
  }, [selectedClient]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedClientId = searchParams.get('selectedClient');
    if (selectedClientId) {
      const matchedClient = clients.find(
        (client) => client.id === Number(selectedClientId)
      );

      if (matchedClient) {
        setSelectedClient([matchedClient]);
      } else {
        setSelectedClient([]);
      }
    }
  }, [location.search, clients]);

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      getSkuList(searchText, 1);
    }, 500);
  }, [selectedClient]);

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    setPageNumber(1);
    debouncedSearch(searchText);
  };

  const getSkuList = async (searchText: string, page: number) => {
    try {
      const { data } = await ApiService().client.get(URL.SKU.GET_SKU_LIST, {
        params: {
          page,
          searchText,
          client_id: selectedClient.length > 0 ? selectedClient[0].id : '',
        },
      });
      if (data.data.length > 0) {
        const updatedSkuList =
          page > 1 ? [...skuList, ...data.data] : data.data;
        setSkuList(updatedSkuList);
        setPageNumber(pageNumber + 1);
        if (updatedSkuList.length == perPage * pageNumber) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } else {
        setSkuList([]);
        setPageNumber(1);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching quotes:', error);
      return [];
    }
  };

  const handleNavigationToDetail = (sku_id: number) => {
    const selectedClientId =
      selectedClient.length > 0 ? selectedClient[0].id : null;

    const url = `/skus/${sku_id}`;

    navigate(url, {
      state: {
        selectedClientId,
      },
    });
  };

  const handleClientSelectionChange = (val: FilterOptionSchema[]) => {
    if (val.length <= 0) {
      setSelectedClient([]);
      setPageNumber(1);
      navigate(`${location.pathname}`, { replace: true });
    } else {
      setSelectedClient(val);
      setPageNumber(1);
      const selectedClientId = val[0].id;
      navigate(`${location.pathname}?selectedClient=${selectedClientId}`, {
        replace: true,
      });
    }
  };

  return (
    <div className="flex-row">
      <SideNav></SideNav>
      <div className="sku-content-container">
        <div className="upper-bar">
          <p className="xetgo-font-tag bold px-16 py-12">
            SKU <span className="sub-title xetgo-font-tag">/ List</span>
          </p>
        </div>

        <div className="sku-lower-main-container flex-1 flex-column gap-12 ">
          {showCreateSku && (
            <div
              onClick={() => setShowCreateSku(false)}
              className="sku-form-modal-main-container full-width "
            >
              <SkuForm closeForm={() => setShowCreateSku(false)} />
            </div>
          )}

          <div className="sku-upper-container flex-row justify-content-space-between align-items-center p-16">
            <div className="search-bar-container">
              <input
                className="py-8 px-34 search-input border-box xetgo-font-tag"
                value={searchedText}
                onChange={handleSearchFile}
                placeholder="Search ..."
              />
              <img
                height={16}
                width={16}
                className="search-icon "
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
                alt="search-icon"
              />
            </div>

            <div className="flex-row align-items-center gap-12">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
                alt="filter"
              />
              <div className="sku-category-filter">
                <Filter
                  option={{
                    key: 'client',
                    name: `${selectedClient.length > 0 ? '' : 'Client'}`,
                  }}
                  optionList={clients || []}
                  multipleSelection={false}
                  onSelection={(val, _option) => {
                    handleClientSelectionChange(val);
                  }}
                  mode="showNames"
                  showPlaceholder={true}
                  direction="right"
                  addNew={false}
                  preSelected={selectedClient ? selectedClient : []}
                  classname="sku-category-filter"
                />
              </div>
              <div
                onClick={() => setShowCreateSku(true)}
                className="create-sku-btn xetgo-font-tag bold py-6 px-12 flex-row align-items-center justify-content-center gap-10 border-box cursor-pointer"
              >
                <img
                  height={10}
                  width={10}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240717130726-Frame+1321314714.svg"
                  alt="sku-icon"
                />
                <p>Create SKU</p>
              </div>
            </div>
          </div>

          <div className="sku-list-lower-container flex-1 flex-column p-16">
            <div className="sku-row sku-list-heading-container">
              <div className="sku-heading-skuId border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703142823-Frame+1321314713.svg"
                  alt="barcode-icon"
                  height={12}
                  width={12}
                />
                <p>SKU ID</p>
              </div>
              <div className="sku-row-right-box">
                <div className="sku-heading-name border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
                    alt="list-icon"
                    height={12}
                    width={12}
                  />
                  <p>Name </p>
                </div>
                <div className=" border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-building-2.svg"
                    alt="company-icon"
                    height={12}
                    width={12}
                  />
                  <p>Company Name</p>
                </div>
                <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240918143510-text.svg"
                    alt="description-icon"
                    height={12}
                    width={12}
                  />
                  <p>Description </p>
                </div>
              </div>
            </div>
            {skuList.length > 0 && (
              <div
                id="scrollableDiv"
                className="sku-list-scroll-wrapper flex-1"
              >
                <InfiniteScroll
                  dataLength={skuList.length}
                  next={() => getSkuList(searchedText, pageNumber)}
                  hasMore={hasMore}
                  loader={<h4></h4>}
                  className="infiniteScrollComponent"
                  scrollableTarget="scrollableDiv"
                >
                  {skuList.length > 0 &&
                    skuList.map((sku, index) => {
                      return (
                        <div
                          onClick={() => handleNavigationToDetail(sku.id)}
                          key={index}
                          className="sku-row sku-products-wrapper flex-row xetgo-font-tag"
                        >
                          <div className="flex-row align-items-center index p-12 border-box">
                            <p className="uuid-container px-12 py-4">
                              {sku.uuid}
                            </p>
                          </div>
                          <div className="">
                            <div className="sku-row-right-box">
                              <p className="sku-name border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
                                {sku.name}
                              </p>

                              <p
                                style={{ color: '#111111' }}
                                className="flex-1 p-12 client-name xetgo-font-tag bold"
                              >
                                {sku.client_name}
                              </p>
                              <p className="flex-1 p-12 description">
                                {sku.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </InfiniteScroll>
              </div>
            )}
            {skuList.length === 0 && (
              <div className="flex-1 sku-empty-container flex-column gap-12 xetgo-font-tag bold align-items-center justify-content-center">
                <img
                  height={24}
                  width={24}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240404074726-glass-box-empty-state.svg"
                  alt="empty-box-icon"
                />
                <p className="sku-empty-phhrase">
                  No SKUs found for selected Client
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Sku;
